import React, { useState } from 'react';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import './ResumeModal.css';
import { PiCloudArrowUpLight } from "react-icons/pi";
import FolderImg from "../../assets/icons/folder2.png";



const ResumeModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '.pdf,.doc,.docx,.ppt,.pptx',
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  const handleUpload = () => {
    // Simulate file upload progress
    const interval = setInterval(() => {
      if (uploadProgress < 100) {
        setUploadProgress((prevProgress) => prevProgress + 10);
      } else {
        clearInterval(interval);
        setUploadProgress(0);
        setFiles([]);
        handleClose();
      }
    }, 500);
  };


  return (
    <>
      <Button className='px-5 py-3 fw-medium btn-outline-primary' onClick={handleShow}>
        Upload
      </Button>

      <Modal show={showModal} onHide={handleClose} >
        <Modal.Header closeButton className='form-control-close'>

        <div className='d-flex align-items-center'>
          <div className='form-header-icon d-flex align-items-center'>
          <PiCloudArrowUpLight size={28} />
          </div>
          
          <div>
            <Modal.Title>Upload Files</Modal.Title>
            <span className="d-block text-muted">Select and upload the files of your choice</span>
          </div>

        </div>

        </Modal.Header>
        

        <Modal.Body>

          <div {...getRootProps()}  className="resume-container">
            <input {...getInputProps()} />

            <div className='mx-2 d-flex align-items-center justify-content-center icon-resume-2'>
            <img src={FolderImg} alt="Description" className='resume-icon' />
          </div>

            <h4 className='fw-normal text-secondary fs-4'>Choose a file or drag & drop it here</h4>
            <span className="d-block text-light-custom fs-6">JPEG, PNG, PDG, and PDF formats, up to 50MB</span>

            {files.length > 0 && (
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            )}
          </div>

          {uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResumeModal;
