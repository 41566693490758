
// import React, { useState } from 'react';
// import './VerticalProgressBar.css';

// interface Step {
//   label: string;
//   class?: string;
// }

// interface VerticalProgressBarProps {
//   steps: Step[];
//   onStepClick: (label: string) => void;
// }

// const VerticalProgressBar: React.FC<VerticalProgressBarProps> = ({ steps, onStepClick }) => {
//   const [checkedItems, setCheckedItems] = useState<boolean[]>(new Array(steps.length).fill(false));

//   const handleCheckboxChange = (index: number) => {
//     const newCheckedItems = [...checkedItems];
//     newCheckedItems[index] = !newCheckedItems[index];
//     setCheckedItems(newCheckedItems);
//   };

//   const progress = Math.floor((checkedItems.filter(item => item).length / steps.length) * 100);


//   return (
//     <div className="progress-bar-container">
//       <div className="navigation_menu" id="navigation_menu">
//         <ul className="navigation_tabs" id="navigation_tabs">
//           {steps.map((step, index) => (
//             <li key={index} className={`progress-step py-1 ${step.class || ''}`}>
//               <label className={`${step.class || ''}`} htmlFor={`step-${index}`} onClick={() => onStepClick(step.label)}>
//                 {step.label}
//               </label>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div className="progress-bar" style={{ height: `${progress}%` }} />
//     </div>
//   );
// };


// export default VerticalProgressBar;






import React, { useState } from 'react';
import './VerticalProgressBar.css';

interface Step {
  label: string;
  class?: string;
}

interface VerticalProgressBarProps {
  steps: Step[];
  onStepClick: (label: string) => void;
}

const VerticalProgressBar: React.FC<VerticalProgressBarProps> = ({ steps, onStepClick }) => {
  const [checkedItems, setCheckedItems] = useState<boolean[]>(new Array(steps.length).fill(false));

  const handleStepClick = (index: number) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
    onStepClick(steps[index].label);
  };

  const progress = Math.floor((checkedItems.filter(item => item).length / steps.length) * 100);

  return (
    <div className="progress-bar-container">
    <div className="navigation_menu" id="navigation_menu">


<ul className="navigation_tabs" id="navigation_tabs" style={{ height: `${steps.length * 50}px` }}>
  {steps.map((step, index) => (
    <li
      key={index}
      className={`progress-step py-1 my-3 ${checkedItems[index] ? 'tab_active' : 'tab_inactive'} ${step.class || ''}`}
      onClick={() => handleStepClick(index)}
    >
      <div className="circle">
        <input
          type="checkbox"
          id={`step-${index}`}
          checked={checkedItems[index]}
          onChange={() => handleStepClick(index)}
        />
      </div>
      <label htmlFor={`step-${index}`} className={`${step.class || ''}`}>
        {step.label}
      </label>
    </li>
  ))}
</ul>


    </div>
    <div className="progress-bar">
      <div className="progress-bar-fill" style={{ height: `${progress}%` }}></div>
    </div>
  </div>
  );
};

export default VerticalProgressBar;