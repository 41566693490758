import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import './forms.css';
import './wrapper.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import LandingPage from './components/landingpage/LandingPage';
import CardItem from './components/reusable/carditem/CardItem';
import ServiceHeader from './components/servicepages/serviceheader/ServiceHeader';
import JobSeekerDashboard from './components/servicepages/jobseekerdashboard/JobSeekerDashboard';
import JobProfileForms from './components/servicepages/jobprofileforms/JobProfileForms';
import SavedJobsResults from './components/servicepages/savedjobsresults/SavedJobsResults';
import SearchJobsResults from './components/servicepages/searchjobsresults/SearchJobsResults';
import JobDetails from './components/servicepages/jobdetails/JobDetails';
import SignUp from './components/signup/SignUp';
import AppliedJobs from './components/servicepages/appliedjobs/AppliedJobs';
import CompanyDetails from './components/servicepages/companydetails/CompanyDetails';
import SucessApplied from './components/servicepages/sucessapplied/SucessApplied';
import AccountSettings from './components/servicepages/accountsettings/AccountSettings';
import ForgotPassword from './components/servicepages/forgotpassword/ForgotPassword';
import ResetPassword from './components/servicepages/forgotpassword/ResetPassword';





type CardDetailsType = {
  title: string;
  description: string;
  imgUrl: string;
  className: string;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const cardDetails: CardDetailsType = {
    title: 'Sample Title',
    description: 'Sample Description',
    imgUrl: 'path/to/image.jpg',
    className: 'sample-class',
  };

  const steps = [
    { label: 'Profile', class: 'tab_inactive' },
    { label: 'Key Skills', class: 'tab_inactive' },
    { label: 'Work Experience', class: 'tab_inactive' },
    { label: 'Education', class: 'tab_inactive' },
    { label: 'Upload CV', displayText: 'Upload Resume', class: 'tab_inactive' },
    { label: 'Preferences', class: 'tab_disabled' },
  ];


    // Define dynamic header class based on route
    const getHeaderClass = () => {
      if (location.pathname === '/') {
        return 'header-home';
      } else if (location.pathname === '/signup') {
        return 'header-signup';
      } else if (isLoggedIn) {
        return 'header-logged-in';
      } else {
        return 'header-default';
      }
    };


  const location = useLocation();

  return (
    <div className="App">
      {/* {location.pathname === '/' ? <Header /> : <ServiceHeader />} */}
      {location.pathname === '/' || location.pathname === '/signup' ? <Header /> : <ServiceHeader />}

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/cardItem" element={<CardItem cardDetails={cardDetails} />} />
        <Route path="/jobSeekerDashboard" element={<JobSeekerDashboard />} />
        <Route path="/savedJobsResults" element={<SavedJobsResults />} />
        <Route path="/searchjobresults" element={<SearchJobsResults />} />
        {/* <Route path="/searchjobresults" element={<SearchJobsResults skills="" location="" />} /> */}
        <Route path="/jobProfileForms" element={<JobProfileForms steps={steps} />} />
        <Route path="/jobdetails/:jobId" element={<JobDetails />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/appliedjobs" element={<AppliedJobs />} />

        <Route path="/companydetails/:companyId" element={<CompanyDetails />} />
        <Route path="/sucessapplied" element={<SucessApplied />} />
        <Route path="/accountsettings" element={<AccountSettings />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />



      </Routes>

      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
