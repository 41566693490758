
// per row 4 cards

import React from 'react';
import './CardItem.css';

type CardDetailsType = {
  title: string;
  description?: string;
  imgUrl?: JSX.Element | string;
  className: string;
};

const CardItem: React.FC<{ cardDetails: CardDetailsType }> = ({ cardDetails }) => {
  const { title, description, imgUrl, className } = cardDetails;

  return (
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4">
      <div className={`${className} card card-item`}>
        {typeof imgUrl === 'string' ? (
          <img className="card-img-top standard-icon" src={imgUrl} alt={title} />
        ) : (
          imgUrl // Render the imgUrl directly if it's a Font Awesome icon
        )}
        <div className="card-body p-0">
          <p className="card-title pt-3 fw-medium align-items-center">{title}</p>
          <small className="card-text">{description}</small>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
