

import React, { useState, useEffect } from 'react';
import './ChatBox.css';
import chatHistoryData from '../../data/UserChatHistory.json';
import { MdSend } from "react-icons/md";
import { BsXLg } from "react-icons/bs";

interface ChatMessage {
  sender: string;
  content: string;
  timestamp: string;
}

interface ChatBoxProps {
  isOpen: boolean;
  closeChat: () => void;
}

const receiverImage = 'https://ui-avatars.com/api/?name=R&size=40';
const senderImage = 'https://ui-avatars.com/api/?name=U&size=40';

const ChatBox: React.FC<ChatBoxProps> = ({ isOpen, closeChat }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    setMessages(chatHistoryData.chatHistory);
  }, []);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const newMessageObject: ChatMessage = {
        sender: 'user',
        content: newMessage,
        timestamp: new Date().toISOString(),
      };
      setMessages([...messages, newMessageObject]);
      setNewMessage('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="chatbox-container">
      <div className="chatbox-header">
        <h4>Chat with Recruiter</h4>
        <BsXLg onClick={closeChat} className="form-control-close" />
      </div>
      <div className="chatbox-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chatbox-message ${message.sender === 'recruiter' ? 'recruiter' : 'user'}`}
          >
            {message.sender === 'recruiter' ? (
              <img src={receiverImage} alt="Receiver" className="sender-image" />
            ) : (
              <img src={senderImage} alt="Sender" className="sender-image user-image" />
            )}
            <div className="message-wrapper">
              <div className="message-content">{message.content}</div>
              <div className="message-timestamp">{new Date(message.timestamp).toLocaleTimeString()}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="chatbox-input">
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
        />
          <div className="btn send-button" onClick={handleSendMessage}><MdSend /> </div>
      </div>


    </div>
  );
};

export default ChatBox;