import 'bootstrap/dist/css/bootstrap.css';
import { Card } from 'react-bootstrap';
import React from 'react';
import './LandingPage.css';
import { BsDeviceSsd, BsSearch } from 'react-icons/bs';
import SearchBar from '../../components/reusable/searchbar/SearchBar';
import { FaWifi , FaDesktop , FaRegClock } from 'react-icons/fa';
//for Swiper images & files
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Adobe from "../assets/images/Adobe.png";
import Amazon from "../assets/images/Amazon.png";
import Microsoft from "../assets/images/Microsoft.png";
import Urban from "../assets/images/Urban.png";
import Zomoto from "../assets/images/Zomoto.png";
//Browse by Category Cards
import CardItem from '../../components/reusable/carditem/CardItem';
import BankingImg  from "../assets/icons/Banking.png";
import BPOImg  from "../assets/icons/BPO.png";
import ConsultingImg  from "../assets/icons/Consulting.png";
import HumanImg  from "../assets/icons/Human.png";
import ITImg  from "../assets/icons/IT.png";
import LegalImg  from "../assets/icons/Legal.png";
import OperationsImg  from "../assets/icons/Operations.png";
import SalesImg  from "../assets/icons/Sales.png";
//Browse by TwoCol
import TwoCol from '../../components/reusable/twocol/TwoCol';
import getaccess from "../assets/images/getaccess.png";
import easilyscreen from "../assets/images/easilyscreen.png";


// Spring Animations
import { useSpring, animated } from 'react-spring';
// Define CardDetailsType with optional description
type CardDetailsType = {
title: string;
description?: string;
imgUrl?: JSX.Element | string;
className: string;
};
type TwoColType = {
title: string;
description?: string;
imgUrl?: JSX.Element | string;
className: string;
versa: boolean; // Added versa prop of type boolean
};
const badgeRow = [
{ badgetext: 'Freshers' },
{ badgetext: 'HR Executive' },
{ badgetext: 'Manual Testing'},
{ badgetext: 'Software Engineer'},
{ badgetext: 'Business Analyst'},
{ badgetext: 'DevOps Engineer'},
{ badgetext: 'Data Analyst'},
{ badgetext: 'Digital Marketing'},
{ badgetext: 'Banking'},
{ badgetext: 'UX/UI'},
];
const jobsType = [
{ imgUrl:
<FaWifi/>
, title: 'Remote Jobs', count: '2,920', className: 'text-primary'},
{ imgUrl:
<FaDesktop/>
, title: 'Full Time Jobs', count: '3,810', className: 'text-secondary icon-color'},
{ imgUrl:
<FaRegClock/>
, title: 'Part Time Jobs', count: '1,258', className: 'text-success'},
];
const LandingPage: React.FC = () => {
const animationProps = useSpring({ opacity: 1, from: { opacity: 0 } });
//Browse by Category Cards
const cardsData: CardDetailsType[] = [
{ title: 'Banking & Finance', imgUrl: BankingImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
{ title: 'BPO', imgUrl: BPOImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
{ title: 'Consulting', imgUrl: ConsultingImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
{ title: 'Human Resources', imgUrl: HumanImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
{ title: 'IT & Systems', imgUrl: ITImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
{ title: 'Legal', imgUrl: LegalImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
{ title: 'Operations', imgUrl: OperationsImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
{ title: 'Sales & Marketing', imgUrl: SalesImg, className: 'bg-white p-5 shadow-none border m-2 text-center rounded-4'},
];

const cardsData2: CardDetailsType[] = [
{ title: '01', description:'Register your account', className: 'bg-how-work-1 p-5 rounded-4 mx-2 shadow-none border fw-medium px-5 py-4'},
{ title: '02', description:'Upload your resume', className: 'bg-how-work-2 p-5 rounded-4 mx-2 shadow-none border fw-medium px-5 py-4'},
{ title: '03', description:'Apply for the job', className: 'bg-how-work-3 p-5 rounded-4 mx-2 shadow-none border fw-medium px-5 py-4'},
];

const twoCol: TwoColType[] = [
{ imgUrl: getaccess,
title: 'Get Access to High-Quality Talent',
description:'Post a job in minutes and quickly connect with relevant candidates',
className: 'p-5',
versa: true,
},
{ imgUrl: easilyscreen,
title: 'Easily Screen Candidates and Validate',
description:'Post a job in minutes, easily screen applicants, and validate qualifications to find the right candidate faster',
className: 'p-5',
versa: false,
},
{ imgUrl: getaccess,
title: 'Use Smart tools',
description:'Tools like chat, video/audio calls, and an interview scheduler help reduce manual tasks and allow you to connect with candidates easily and efficiently',
className: 'p-5',
versa: true,
},
];
return (
<div className='bg-white'>

   <div className='vertical-gradient'>
   <div className='container'>
      <div className="justify-content-center text-center row">
         <div className='col-lg-12 col-xl-8 py-5'>
         <h2 className="text-center display-6 fw-bold mt-5">The Easiest Way to Get Your New Job</h2>
            <div className='d-flex justify-content-center'>
               <div className='col-md-8'>
                  <p className="fs-6 pt-2">Each month, more than 3 million job seekers turn to websites in their search for work, making over 140,000 applications every single day</p>
               </div>
            </div>
            </div>
      </div>


      <div className="justify-content-center text-center row">
         <div className='col-lg-12 col-xl-8 pb-5'>
       <SearchBar />
      </div>
     </div>


      <div className='py-5 callout'>
         <div className="row">
            {jobsType.map((card, index) => (
            <div key={index} className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-4">
               <Card className='p-5 rounded-4 border shadow-none mx-2'>
                  <Card.Body className='p-0'>
                     <div className='p-1'>
                        <div className='d-lg-flex d-sm-block justify-content-between'>
                           <div className={`${card.className}`}>
                              {card.imgUrl}
                           </div>
                           <div className=''>
                              <div className='d-flex sub-text ps-4 h5 justify-content-start text-start'>
                                 {card.title}
                              </div>
                           </div>
                           <div className='text-end w-50'>
                              <h3 className='jjustify-content-start text-end mb-0 fw-bold'>{card.count}</h3>
                              <p className='justify-content-start p-0 m-0 text-end'>Available</p>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
            ))}
         </div>
      </div>

   </div>
   </div>






<div className="container py-5">
   <div className="row align-items-center">
      <div className="col-md-3 mb-2 mb-md-0 text-start">
         <h5 className="pe-6 card-title fw-medium pb-3">Trusted by 100+<br/>Top Companies Worldwide</h5>
      </div>
      <div className="col-md-9">
         <Swiper
            spaceBetween={50}
            centeredSlides={true}
            loop={true}
            aria-live="off"
            id="swiper-wrapper-72a332a38108fc585"
            breakpoints={{
               // Show 1 slide on screens smaller than 576px (mobile)
               0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
               },
               // Show 2 slides on small screens >= 576px
               576: {
                  slidesPerView: 2,
                  spaceBetween: 30,
               },
               // Show 3 slides on medium screens >= 768px
               768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
               },
               // Show 4 slides on large screens >= 992px
               992: {
                  slidesPerView: 4,
                  spaceBetween: 50,
               },
            }}
         >

            <SwiperSlide className="swiper-slide" aria-label="1 / 6">
               <img src={Adobe} alt="Adobe Image" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide" aria-label="2 / 6">
               <img src={Amazon} alt="Amazon Image" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide" aria-label="3 / 6">
               <img src={Microsoft} alt="Microsoft Image" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide" aria-label="4 / 6">
               <img src={Urban} alt="Urban Image" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide" aria-label="5 / 6">
               <img src={Zomoto} alt="Zomoto Image" />
            </SwiperSlide>
         </Swiper>
      </div>
   </div>
</div>




   <div className='container'>
      <div className="justify-content-center row pt-3 my-3">
              <h2 className='text-center display-6 fw-semibold'>Browse by Category</h2>
               <p className="fs-6 pt-2">Find the job that's perfect for you. About 800+ new jobs every day.</p>
      </div>
      <animated.div style={animationProps}>
         <div className="align-items-center justify-content-center text-center row pt-4">
            {cardsData.map((card, index) => (
            <CardItem key={index} cardDetails={card} />
            ))}
         </div>
      </animated.div>
   </div>



   <div className='container py-5'>
      <div className="justify-content-center row my-3">
                <h2 className='text-center display-6 fw-semibold'>How it Works</h2>
               <p className="fs-6 pt-2">Explore the steps below to help you to find a job easily</p>
      </div>
      <div className="align-items-center justify-content-center text-center row pt-4">
         {cardsData2.map((card, index) => (
         <CardItem key={index} cardDetails={card} />
         ))}
      </div>
   </div>



   <div className='pt-5'>
      <div className='landig-page-two-col-bg pt-5 '>
         <div className='container-md pb-5'>
            <div className="justify-content-center row mt-4">
               <h2 className='text-center display-6 fw-semibold'>Hiring is Now Simple</h2>
               <p className="fs-6 pt-2">Connect with top-quality candidates across leading domains</p>
            </div>
            <div className='pt-3'>
               <div className='row'>
                  {twoCol.map((card, index) => (
                  <TwoCol key={index} twoCol={card} />
                  ))}
               </div>
            </div>
         </div>
      </div>
   </div>




   <div className='pt-5 pb-5'>
      <div className='bg-white pt-5'>
         <div className='container-md'>
            <div className="justify-content-start d-flex">
               <h2 className='text-center display-6 fw-semibold'>Popular Searches</h2>
            </div>
            <div className='pt-4'>
               <div className='row'>
                  <div className='col-10'>
                     <div className='d-flex justify-content-start flex-wrap'>
                        {badgeRow.map((card, index) => (
                        <div key={index} className="jbadge-container">
                           <span className="jbadge small rounded-5 p-4 py-3 me-3 mb-3">{card.badgetext}</span>
                        </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</div>
);
};
export default LandingPage;