

import React, { useState } from 'react';
import './LoginWrapper.css';
import { FaGoogle } from 'react-icons/fa';
import { BsXLg } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import googleicon from "../assets/icons/google.svg";

interface LoginWrapperProps {
  children: React.ReactNode;
}

const LoginWrapper: React.FC<LoginWrapperProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="drawer-wrapper">
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <div className="d-flex justify-content-end pt-4">
          {isDrawerOpen && (
            <BsXLg onClick={closeDrawer} className="form-control-close" />
          )}
        </div>

        <div className='p-3'>

        <div className='pt-4 pb-4'>
          <h6>Create an account or sign in</h6>
          <h5>before applying to the company site</h5>
        </div>

        <div className='d-flex justify-content-center'>


        <button type="button" className="login-with-google-btn" >
          Sign in with Google
        </button>

        </div>


        <div className="my-5">
          <div className="text">Or Login With</div>
        </div>
        <div className="email-login px-5">
          <div>
            <label className='d-flex justify-content-start pb-1 form-label' htmlFor="emailInput">Email ID/Username</label>
            <input className='form-control-custom' type="email" id="emailInput" placeholder="Enter your email /username" />
          </div>

          <div className='py-3'>
            <label className='d-flex justify-content-start pb-1 form-label' htmlFor="Password">Password</label>
            <input className='form-control-custom' type="password" placeholder="Password" />
          </div>

          <div className="options">
            <span className='fs-6 fw-medium d-flex justify-content-end primary-text'><Link to="/forgotpassword">Forgot Password?</Link></span>
          </div>
          <div className='d-flex justify-content-start my-4'>
            <Link to="/jobProfileForms" className="btn-primary py-3 rounded-1 w-100 text-decoration-none fw-bold fs-6">Login</Link>
          </div>
        </div>
        <Link to="/signup" className="fw-medium fs-6 primary-text mt-3">Do you want to create an account?</Link>


        </div>



      </div>



      <div className="main-content">
        <button onClick={openDrawer} className="btn btn-outline-primary rounded-pill px-4 py-2">
          Login
        </button>
        {children}
      </div>

    </div>
  );
};

export default LoginWrapper;