import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { PiWalletThin, PiSuitcaseSimpleThin, PiMapPinLineThin, PiStudentThin, PiWrenchThin, PiBookmarkSimpleThin, PiBookmarkSimpleFill, PiShareNetworkThin } from "react-icons/pi";
import { IoShareSocial } from "react-icons/io5";
import "./JobCardReUse.css"


import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { MdBookmarkBorder } from "react-icons/md";
import { IoMdShare } from "react-icons/io";



interface JobCardReUseProps {
  key: number;
  jobRole: string;
  companyName: string;
  package: string;
  experience: string;
  education?: string;
  locations: string[];
  skills: string[];
  postedDate: string;
  image: string;
  isInitiallyBookmarked?: boolean;
  removeMarginBottom?: boolean;
  onJobClick?: () => void;
  onImageClick?: () => void;
  onCompanyClick?: () => void;
}

const JobCardReUse: React.FC<JobCardReUseProps> = ({
  jobRole,
  companyName,
  package: jobPackage,
  experience,
  locations,
  education,
  skills,
  postedDate,
  image,
  isInitiallyBookmarked = false,
  removeMarginBottom = false,
  onJobClick,
  onImageClick,
  onCompanyClick,
}) => {


  const [isBookmarked, setIsBookmarked] = useState(isInitiallyBookmarked);

  const handleBookmarkClick = () => {
    setIsBookmarked(!isBookmarked);
  };


  return (
    <div className="job-card">
      <Card className={removeMarginBottom ? '' : 'mb-4 no-shadow border shadow-none rounded-4 p-2 mb-4'}>
        <Card.Body className='p-4'>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <div>
              <Card.Title className='fw-semibold h5' onClick={onJobClick} style={{ cursor: onJobClick ? 'pointer' : 'default' }}>{jobRole}</Card.Title>
              <div className='small text-primary text-start fw-medium' onClick={onCompanyClick} style={{ cursor: onCompanyClick ? 'pointer' : 'default' }}> {companyName}</div>
            </div>
            <div className="d-flex align-items-center text-primary">
              <img src={image} alt={`${companyName} logo`} className="job-card-image" width={36} onClick={onImageClick} style={{ cursor: onImageClick ? 'pointer' : 'default' }} />
            </div>
          </div>

          <div className="d-flex flex-wrap mt-2 align-items-center">
            <div className="d-flex align-items-center text-secondary pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
              <LuWallet className="icon-size fs-6" /> <span className="ms-2 fw-normal">{jobPackage}</span>
            </div>

            <div className="d-flex align-items-center text-secondary pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
              <PiSuitcaseSimpleLight className="icon-size" /><span className="ms-2 fs-6 fw-normal">{experience} Years</span>
            </div>

            <div className="d-flex align-items-center text-secondary py-2 margin-right-custom">
              <CiLocationOn className="icon-size" /> <span className="ms-2 fw-normal">{locations.join(', ')}</span>
            </div>
          </div>

          {education && (
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex align-items-center text-secondary pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
                <HiOutlineAcademicCap className="icon-size" /> <span className="ms-2 fw-normal">{education}</span>
              </div>
            </div>
          )}

          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center text-secondary pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
              <IoSettingsOutline className="icon-size" /> <span className="ms-2 fw-normal">{skills.join(', ')}</span>
            </div>
          </div>

          {/* <hr className='border-color' /> */}

          <div className='d-flex justify-content-between border-top mt-3 pt-4 align-items-center'>
            <div className="d-flex align-items-center" onClick={handleBookmarkClick}>
            {isBookmarked ? <PiBookmarkSimpleFill className="icon-size-filled" /> : <PiBookmarkSimpleThin className="icon-size" />}
            </div>
            <div className='d-flex align-items-center'>
              <div className='text-secondary'>Posted: {postedDate}</div>
              <div className='mx-3'><IoShareSocial className="icon-size" /></div>
            </div>
          </div>

        </Card.Body>
      </Card>
    </div>
  );
};

export default JobCardReUse;
