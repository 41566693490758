import React, { useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FolderImg from "../../assets/icons/folder2.png";

interface ProfileFormProps {
  onSubmit: (data: UploadCvData) => void;
}

interface UploadCvData {
  files: File[];
}



const UploadCv: React.FC<ProfileFormProps> = ({ onSubmit }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '.pdf,.doc,.docx,.ppt,.pptx',
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  const handleUpload = () => {
    const interval = setInterval(() => {
      if (uploadProgress < 100) {
        setUploadProgress((prevProgress) => prevProgress + 10);
      } else {
        clearInterval(interval);
        setUploadProgress(0);
        setFiles([]);
      }
    }, 500);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({ files });
  };

  return (
    <form onSubmit={handleSubmit} className="my-form">

             <legend>
              <h6 className="fw-medium fs-4">Upload Resume</h6>
              <hr className="w-100 border-color-2" />
            </legend>
      <div>

<div {...getRootProps()}  className="resume-container">
            <input {...getInputProps()} />

            <div className='mx-2 d-flex align-items-center justify-content-center icon-resume-2'>
            <img src={FolderImg} alt="Description" className='resume-icon' />
          </div>

            <h4 className='fw-normal text-secondary fs-4'>Choose a file or drag & drop it here</h4>
            <span className="d-block text-light-custom fs-6">JPEG, PNG, PDG, and PDF formats, up to 50MB</span>

            {files.length > 0 && (
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            )}
          </div>


        {uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}

        <Button type="submit" className="btn-forms-outline">Back</Button>
        <Button type="submit" className='btn-forms-primary' onClick={handleUpload}>Save and Continue</Button>

      </div>
    </form>
  );
};

export default UploadCv;
