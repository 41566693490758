import React from 'react';
import { Link } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  return (
    <div className="create-password-page js-pg-hg">
      <div className="container">
        <div className="row align-items-center justify-content-center my-5 py-3">
          <div className="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-11 mt-5 mt-xl-0">
            <div className="card shadow-sm d-flex mt-5 mt-xl-0 bx-center border-0 p-4">
              <div className="card-body text-start">
                <form>
                  <h4>Reset Password</h4>
                  <label>
                    Lost your password? Please enter your email address. You will receive a link to create a new password via email
                  </label>
                  <div className="mt-4 text-start">
                    <div className="form-group mb-4">
                      <label htmlFor="email" className="form-label">
                        Email <span className='red'>*</span>
                      </label>
                      <input
                        id="email"
                        className="form-control-custom"
                        type="email"
                        placeholder="Enter email address"
                      />
                    </div>
                  </div>
                  <Link to="/resetpassword" className="btn btn-md px-5 py-3 w-100 mt-2 btn-primary">
                    Reset Password
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
