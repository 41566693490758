
import React, { useState } from 'react';
import { Modal, Button, Form, Badge, ListGroup } from 'react-bootstrap';
import { RiToolsFill, RiToolsLine } from "react-icons/ri";
import './TopSkillsModal.css';

const TopSkillsModal: React.FC = () => {
  const skillsArray = ['JavaScript', 'React', 'Node.js', 'HTML', 'CSS', 'TypeScript', 'Python', 'UX Design', 'UI Development'];
  const [showModal, setShowModal] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setSearchValue('');
    setShowDropdown(false);
  };

  const handleShow = () => setShowModal(true);

  const handleAddSkill = (skill: string) => {
    if (!selectedSkills.includes(skill)) {
      setSelectedSkills([...selectedSkills, skill]);
      setSearchValue(''); // Clear search input after adding a skill
      setShowDropdown(false); // Hide dropdown after selection
    }
  };

  const handleRemoveSkill = (skill: string) => {
    setSelectedSkills(selectedSkills.filter((s) => s !== skill));
  };

  const filteredSkills = skillsArray.filter((skill) =>
    skill.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setShowDropdown(e.target.value !== ''); // Show dropdown if search input is not empty
  };

  return (
    <>
      <Button variant="btn text-primary" onClick={handleShow}>
        <RiToolsFill /><br/>
        <span className='fw-medium'>Add Skills</span>
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton className='form-control-close'>

        <div className='d-flex align-items-center'>
          <div className='form-header-icon d-flex align-items-center'>
          <RiToolsLine size={28} />
          </div>
          
          <div>
          <Modal.Title>Skills</Modal.Title>
          </div>

        </div>
         
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="addSkills">
            <Form.Label className="form-label">Add Skills</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter skill name"
              value={searchValue}
              onChange={handleSearchChange}
              className="form-control-lg form-control-custom"
            />
          </Form.Group>

          {/* Dropdown list for filtered skills */}
          {showDropdown && filteredSkills.length > 0 && (
            <ListGroup className="mt-2">
              {filteredSkills.map((skill) => (
                <ListGroup.Item
                  key={skill}
                  action
                  onClick={() => handleAddSkill(skill)}
                  style={{ cursor: 'pointer' }}
                >
                  {skill}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}

          <div className="selected-skills mt-4">
            {selectedSkills.map((skill) => (
              <Badge
                key={skill}
                pill
                className="custom-badge"
                onClick={() => handleRemoveSkill(skill)}
              >
                {skill} <span style={{ marginLeft: '5px' }}>x</span>
              </Badge>
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer className='d-flex justify-content-start mx-3'>
          <Button variant="primary" onClick={handleClose} className='px-4 py-2'>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TopSkillsModal;
