import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Education from '../education/Education';

interface WorkExperienceFormProps {
  onSubmit: (data: WorkExperienceData | EducationData) => void;
}

interface WorkExperienceData {
  companyname: string;
  designation: string;
  workingfromdate: string;
  workingtodate: string;
  currentlyWorking: boolean;
}

interface EducationData { // Define EducationData here
  degreeType: string;
  specialization: string;
  startDate: string;
  endDate: string;
  currentlyPursuing: boolean;
}

const WorkExperience: React.FC<WorkExperienceFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<WorkExperienceData>({
    companyname: '',
    designation: '',
    workingfromdate: '',
    workingtodate: '',
    currentlyWorking: false,
  });

  const [activeForm, setActiveForm] = useState<'WorkExperience' | 'Education'>('WorkExperience');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
    setActiveForm('Education'); // Switch to Education form after submission
  };

  const renderForm = () => {
    switch (activeForm) {
      case 'WorkExperience':
        return (
          <Form onSubmit={handleSubmit} className="my-form">
            <legend>
              <h6 className="fw-medium fs-4">My Work Experience</h6>
              <hr className="w-100 border-color-2" />
            </legend>
            <div className='row'>
              <Form.Group controlId="companyname" className='col-md-6 col-sm-12 mb-4'>
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="companyname"
                  value={formData.companyname}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Enter company name"
                />
              </Form.Group>

              <Form.Group controlId="designation" className='col-md-6 col-sm-12 mb-4'>
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Enter designation"
                />
              </Form.Group>

              <Form.Group controlId="workingfromdate" className='col-md-6 col-sm-12 mb-4'>
                <Form.Label>Working From Date</Form.Label>
                <Form.Control
                  type="date"
                  name="workingfromdate"
                  value={formData.workingfromdate}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                />
              </Form.Group>

              <Form.Group controlId="workingtodate" className='col-md-6 col-sm-12 mb-4'>
                <Form.Label>Working To Date</Form.Label>
                <Form.Control
                  type="date"
                  name="workingtodate"
                  value={formData.workingtodate}
                  onChange={handleChange}
                  required={!formData.currentlyWorking}
                  className="form-control-custom mt-2"
                  disabled={formData.currentlyWorking}
                />
              </Form.Group>

              <Form.Group controlId="currentlyWorking" className="col-md-6 col-sm-12 mb-3">
                <Form.Check
                  type="checkbox"
                  label="Currently Working"
                  checked={formData.currentlyWorking}
                  onChange={(e) => setFormData({ ...formData, currentlyWorking: e.target.checked })}
                  className="pt-2"
                />
              </Form.Group>
            </div>
            <Button type="submit" className="btn-forms-outline">Back</Button>
            <Button type="submit" className='btn-forms-primary'>Save and Continue</Button>
          </Form>
        );

      case 'Education':
        return <Education onSubmit={onSubmit} />;

      default:
        return null;
    }
  };

  return <div>{renderForm()}</div>;
};

export default WorkExperience;
