import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Dropdown, Form } from 'react-bootstrap';
import "./SavedJobsResults.css";
import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';
import SideFilters2 from '../../reusable/sidefilters/SideFilters2';

const SavedJobsResults: React.FC = () => {
  const savedjobscount = '30';
  const showingresultscount = "1 - 15 of 24039";
  const savedCompanies = ['Tech Mahindra', 'TCS', 'Facebook', 'etc...'];
  const workModes = ['WFH', 'WFO', 'Hybrid'];
  const jobTypes = ['Permanent', 'Contract', 'Part Time'];
  const locations = ['Hyderabad', 'Banglore', 'Chennai', 'Mumbai', 'Delhi'];
  const departments = ['IT', 'Finance', 'Banking'];
  const educations = ['Graduation', 'Post Graduation'];
  const companyType = ['MNC', 'Startup'];


  interface JobCardReUseProps {
    id: number;
    jobRole: string;
    companyName: string;
    package: string;
    experience: string;
    locations: string[];
    education: string;
    skills: string[];
    postedDate: string;
    image: string;
    isInitiallyBookmarked?: boolean;
  }

  
  interface FilterCardsProps {
    id: number;
    yearsexperience?: string;
    filtertittle: string;
    options?: string[];
  }

  const jobRecommendations: JobCardReUseProps[] = [
    {
      id: 1,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '₹18.4L - ₹19.8L',
      experience: '3-6',
      locations: ['Hdyerabad', 'Banglore'],
      education:'Becherlors, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: true,
    },
    
    {
      id: 2,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '₹18.4L - ₹19.8L',
      experience: '6-10',
      education:'Becherlors, ',
      locations: ['Chennai', 'Mumbai'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: true,
    },
    {
      id: 3,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '₹18.4L - ₹19.8L',
      experience: '3-6',
      locations: ['Hdyerabad', 'Banglore'],
      education:'Becherlors, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: true,
    },
    
    {
      id: 4,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '₹18.4L - ₹19.8L',
      experience: '6-10',
      education:'Becherlors, ',
      locations: ['Hdyerabad', 'Mumbai'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: true,
    },
    
  ];


  const filtercards: FilterCardsProps[] = [
    {
      id: 1,
      filtertittle: "Experience",
      yearsexperience: "0-20 Years",
    },
    {
      id: 2,
      filtertittle: "Work Mode",
      options: workModes,
    },
    {
      id: 3,
      filtertittle: "Job Type",
      options: jobTypes,
    },
    {
      id: 4,
      filtertittle: "Locations",
      options: locations,
    },
    {
      id: 5,
      filtertittle: "Department",
      options: departments,
    },
    {
      id: 6,
      filtertittle: "Education",
      options: educations,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
 

  const handleJobClick = (jobId: number) => {
    navigate(`/jobdetails/${jobId}`);
  };
  
  const handleCompanyClick = (companyName: string) => {
    navigate(`/companydetails/${companyName}`);
  };
  
  const handleImageClick = (jobId: number) => {
    navigate(`/jobdetails/${jobId}`);
  };


  return (
    <div className='js-pg-hg'>
      <div className='container'>
        <div className='row'>

          <div className='col-md-4'>
            <div className='pb-4'>
              <h2 className='d-flex justify-content-start h3'>{savedjobscount} Saved Jobs</h2>
              <span className='d-flex justify-content-start'>{savedCompanies.join(', ')}</span>
            </div>
            <div>
              {filtercards.map((filter) => (
                <SideFilters2
                  key={filter.id}
                  filtertittle={filter.filtertittle}
                  yearsexperience={filter.yearsexperience}
                  options={filter.options}
                />
              ))}
            </div>
          </div>

          <div className='col-md-8'>
            <div>
              <div className='d-flex justify-content-between'>
              <p className='d-flex justify-content-start text-secondary small'>{showingresultscount}</p>
              <p className='d-flex justify-content-start text-primary small'><a className='text-decoration-none fw-bold' href="/jobProfileForms">Upload your Resume </a></p>
              </div>
              {jobRecommendations.map((job) => (
                <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  education={job.education}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                  isInitiallyBookmarked={job.isInitiallyBookmarked}
                  onJobClick={() => handleJobClick(job.id)}
                  onImageClick={() => handleImageClick(job.id)}
                  onCompanyClick={() => handleCompanyClick(job.companyName)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedJobsResults;
