import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PiCheckCircleFill } from "react-icons/pi";

type FormSuccessModalProps = {
  show: boolean;
  handleClose: () => void;
  goToDashboard: () => void;
};

const FormSuccessModal: React.FC<FormSuccessModalProps> = ({ show, handleClose, goToDashboard }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>

      <Modal.Body className="text-center p-4 py-5 my-5">
        <PiCheckCircleFill className="icon-size-success-applied text-success mb-3" size={48} />

        <h2 className="mb-2">Profile Successfully Created</h2>
        <p className="text-muted">Your Profile is Ready, New Opportunities Await!</p>
        <Button
          variant="primary"
          className="btn-forms-primary"
          onClick={goToDashboard}
        >
          Go to Profile Dashboard
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default FormSuccessModal;
