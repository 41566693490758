import React from 'react';
import { Link } from 'react-router-dom';
import './NotificationsList.css';

interface NotificationsListProps {
  notifications: {
    heading: string;
    content: string;
    link: string;
  }[];
}

const NotificationsList: React.FC<NotificationsListProps> = ({ notifications }) => {
  return (

            <p className='p-0 mx-0 px-1'>
               {notifications.map((notification, index) => (
            <div className='border-bottom d-block p-4' key={index}>
            <div className='d-flex justify-content-between px-0 py-4 pt-2'>
              <div>
                <h5 className="card-title fs-6 mb-1">{notification.heading}</h5>
              </div>
              <div className="card-text small">Jan 8</div>
            </div>
            <div className='d-flex'>
              <div className="card-text small text-start">
                <p>Hello Michael, your account expire soon. please renewal your account please contact admin. for renewal please follow below link</p>
                <button className='btn p-0 m-0 btn-link'>https://bitly.hsjdsh</button>
              </div>
            </div>
            </div>
      ))}

          </p>
  );
};

export default NotificationsList;