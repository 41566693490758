import React, { useState, useCallback, useMemo, useEffect } from 'react';
//import './LoginWrapper.css';
import { FaGoogle } from 'react-icons/fa';
import { BsXLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { MdNotificationsNone} from 'react-icons/md';
import { PiBellLight } from "react-icons/pi";
import NotificationsList from '../../notifications/NotificationsList/NotificationsList';

interface SavedJobsWrapperProps {
  children: React.ReactNode;
}

const NotificationWrapper: React.FC<SavedJobsWrapperProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const unreadnotifications = useMemo(() => 4, []);

  const notificationsdata = [
    {
      heading: "Update Available",
      content: "A new version of the app is available. Please update to the latest version for new features and improvements.",
      link: "#"
    },
    {
      heading: "Scheduled Maintenance",
      content: "The system will undergo maintenance on Saturday at 2 AM. Please save your work and log out before this time.",
      link: "#"
    },
    {
      heading: "Security Alert",
      content: "We detected an unusual login attempt. If this wasn't you, please secure your account immediately.",
      link: "#"
    }
  ];

  useEffect(() => {
    return () => {
      setIsDrawerOpen(false);
    };
  }, []);

  return (
    <div className="drawer-wrapper">
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>

      <div className='d-flex justify-content-between border-bottom py-3 p-4 px-0 align-items-center vertical-bottom'>
        <div className='ps-4 pb-3 pt-4'>
          <h5>You have 04 unread Notification</h5>
        </div>
        <div className="fw-bold ms-4 form-control-close">
            {isDrawerOpen && (
              <BsXLg onClick={closeDrawer} className="fw-bold ms-4 form-control-close" />
            )}
          </div>

      </div>


        <NotificationsList notifications={notificationsdata} />

        <div>
        </div>

      </div>
      <div className="main-content">
        <div onClick={openDrawer} className="py-2 service-header-icons">
          <MdNotificationsNone size={32}/>
        </div>
        {children}
      </div>
    </div>
  );
};

export default NotificationWrapper;