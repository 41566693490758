
import React from 'react';
import './Footer.css';


const Footer: React.FC = () => {
    return (
        <div className='bg-white'>
            <div className="container ">
                <footer className="py-3 my-4">
                    <ul className="nav justify-content-start border-bottom pb-3 mb-3">
                        <li className="nav-item"><a href="#" className="nav-link px-3">About</a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-3">Careers</a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-3">Culture</a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-3">Contact Us </a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-3">Interview Tips</a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-3">Sitemap</a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-3">Privacy Refund</a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-3">Policy Terms</a></li>
                    </ul>
                    <p className="text-start">© 2024 Jobsco. All Rights Reserved.</p>
                </footer>
            </div>
        </div>
        
    );
};

export default Footer;