
import React from 'react';
import { Card, Row, Col, CardBody } from 'react-bootstrap';
import { PiWalletThin, PiSuitcaseSimpleThin, PiMapPinLineThin, PiStudentThin, PiWrenchThin, PiPhoneThin, PiEnvelopeSimpleThin, PiCalendarBlankThin  } from "react-icons/pi";
import { Tabs, Tab } from 'react-bootstrap';
import "./UserData.css";

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';




interface UserDataProps {
  key: number;
  jobseeker: string;
  jobskeer_id: number;
  jobseeker_package: string;
  jobseeker_experience: string;
  jobseeker_locations: string[];
  jobseeker_role:string;
  jobseeker_education: string;
  jobseeker_mobilenumber: number;
  jobseeker_mail: string;
  jobseeker_pref_locations: string[];
  jobseeker_notice_period: string;
  jobseeker_about: string;
  jobseeker_skills: string[];
  jobseeker_tools: string[];
  attachedCV: string;
  jobseeker_birth: string;
  jobseeker_gender: string;
  jobseeker_martial_status: string;
  jobseeker_physically_challenged: string;
  
  // Jobskeer Wor Experience
  jobseker_company_name: string;
  jobRole: string;
  working_from_date: string;
  working_to_date: string;
  currently_working:string;


    // Jobskeer Education Details
    education_name: string;
    specilization: string;
    university_name: string;
    course_start_date: string;
    course_completed_date: string;
    currently_pursuing:string;

}




const UserData: React.FC<UserDataProps> = ({
  jobseeker,
  jobskeer_id,
  jobseeker_package: jobPackage,
  jobseeker_experience,
  jobseeker_locations,
  jobseeker_role,
  jobseeker_education,
  jobseeker_mobilenumber,
  jobseeker_mail,
  jobseeker_pref_locations,
  jobseeker_notice_period,
  jobseeker_about,
  jobseeker_skills,
  jobseeker_tools,
  attachedCV,
  jobseeker_birth,
  jobseeker_gender,
  jobseeker_martial_status,
  jobseeker_physically_challenged,

  // Jobseeker Work Experience
  jobseker_company_name,
  jobRole,
  working_from_date,
  working_to_date,
  currently_working,


      // Jobskeer Education Details
      education_name,
      specilization,
      university_name,
      course_start_date,
      course_completed_date,
      currently_pursuing,

}




) => {

  const isPDF = attachedCV.toLowerCase().endsWith('.pdf');

  return (
    <div className="job-card">
      <div className='mb-3'>
        <div>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <div>
            <h6 className='fw-bold fs-5 text-start'>{jobseeker}</h6>
            <span className='small text-primary text-start d-flex justify-content-start'>{jobskeer_id}</span>
            </div>
          </div>




<div className="d-flex flex-wrap align-items-center">
  <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
    <PiWalletThin className="icon-size" /> <span className="ml-1">{jobPackage}</span>
  </div>

  <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
    <PiSuitcaseSimpleThin className="icon-size" /> <span className="ml-1">{jobseeker_experience} Years</span>
  </div>

  <div className="d-flex align-items-center text-secondary small py-2 margin-right-custom">
    <PiMapPinLineThin className="icon-size" /> <span className="ml-1">{jobseeker_locations.join(', ')}</span>
  </div>

  <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
    <PiSuitcaseSimpleThin className="icon-size" /> <span className="ml-1">{jobseeker_role}</span>
  </div>

</div>




<div className="d-flex flex-wrap align-items-center">
  <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom text-start">
    <PiStudentThin className="icon-size" /> <span className="ml-1">{jobseeker_education}</span>
  </div>
</div>




<div className="d-flex flex-wrap align-items-center">
  <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
    <PiPhoneThin className="icon-size" /> <span className="ml-1">{jobseeker_mobilenumber}</span>
  </div>

  <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
    <PiEnvelopeSimpleThin  className="icon-size" /> <span className="ml-1">{jobseeker_mail}</span>
  </div>

</div>


<hr className='border-color' />



<div className="d-flex justify-content-start flex-wrap align-items-center">
  <div className="align-items-center small mb-2 py-2 mb-md-0 margin-right-custom">
     <small className='d-flex justify-content-start text-light-custom '>Preffered Locations</small>
     <span className="d-flex justify-content-start text-secondary fs-6">{jobseeker_pref_locations.join(', ')}</span>
  </div>

  <div className="align-items-center small mb-2 py-2 mb-md-0 margin-right-custom">
     <small className='d-flex justify-content-start text-light-custom'>Notice Period</small>
     <span className="d-flex justify-content-start text-secondary fs-6">{jobseeker_notice_period}</span>
  </div>

</div>



{/* Add 4 Tabs Here include dummy sample data */}


<Tabs defaultActiveKey="tab1" id="jobseeker-tabs" className="mt-5 custom-tabs fs-6">
  <Tab eventKey="tab1" title="Profile & Skills">
    <div className="tab-content">
      
    
  <Card className='mb-3 shadow-none border mt-4'>
   <Card.Body>
         <div className='p-4'>
      <div className='d-flex justify-content-between align-items-center text-start font-weight-normal'>
            <span className='text-secondary fs-6'>{jobseeker_about}</span>
      </div>
         </div>
   </Card.Body>
</Card>




<div className="mt-5">
  <h6 className="text-start text-dark fw-bold">Skills</h6>
  <ul className="pt-2 list-unstyled d-flex flex-wrap">
    {jobseeker_skills.map((skill, index) => (
      <li key={index} className="me-2 mb-2">
        <div className="border text-secondary fw-normal p-2 px-3 fs-6">
          {skill}
        </div>
      </li>
    ))}
  </ul>
</div>



<div className="mt-5">
  <h6 className="text-start text-dark fw-bold">Tools</h6>
  <ul className="pt-2 list-unstyled d-flex flex-wrap">
    {jobseeker_tools.map((tools, index) => (
      <li key={index} className="me-2 mb-2">
        <div className="border text-secondary fw-normal p-2 px-3 fs-6">
          {tools}
        </div>
      </li>
    ))}
  </ul>
</div>




<div className='mt-5'>
 <div className="d-flex justify-content-start flex-wrap align-items-center">
  <div className="align-items-center mb-2 py-4 mb-md-0 margin-right-custom p-4 border">
     <small className='d-flex justify-content-start text-light-custom'>Date of birth</small>
     <span className="d-flex justify-content-start text-secondary fs-6">{jobseeker_birth}</span>
  </div>

  <div className="align-items-center  mb-2 py-4 mb-md-0 margin-right-custom p-4 border">
     <small className='d-flex justify-content-start text-light-custom'>Gender</small>
     <span className="d-flex justify-content-start text-secondary fs-6">{jobseeker_gender}</span>
  </div>

  <div className="align-items-center mb-2 py-4 mb-md-0 margin-right-custom p-4 border">
     <small className='d-flex justify-content-start text-light-custom'>Martial Status</small>
     <span className="d-flex justify-content-start text-secondary fs-6">{jobseeker_martial_status}</span>
  </div>

  <div className="align-items-center mb-2 py-4 mb-md-0 margin-right-custom p-4 border">
     <small className='d-flex justify-content-start text-light-custom'>Physically Challenged</small>
     <span className="d-flex justify-content-start text-secondary fs-6">{jobseeker_physically_challenged}</span>
  </div>

 </div>
</div>




    </div>
  </Tab>



  <Tab eventKey="tab2" title="Work Experience">
  <div className="tab-content">
    <Card className='mb-3 mt-4'>
      <Card.Body>
        <Card.Title className='small font-weight-bold text-start'>{jobRole}</Card.Title>
        <div className='small text-secondary text-start'>{jobseker_company_name}</div>
        
        <div className="d-flex flex-wrap align-items-center my-3">
          <div>
              <small className='d-flex justify-content-start text-light-custom p-1'>working from</small>
              <div className="d-flex align-items-center text-secondary small mb-2 py-2 mb-md-0 margin-right-custom">
                <PiCalendarBlankThin className="icon-size" /> 
                <span className="ml-1">{working_from_date}</span>
           </div>
          </div>

          <div>
              <small className='d-flex justify-content-start text-light-custom'>working to</small>
              <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
                <PiCalendarBlankThin className="icon-size" /> 
                <span className="ml-1">{working_to_date}</span>
           </div>
          </div>

        </div>

        {currently_working === 'Yes' && (
          <div className='d-flex justify-content-start'>
            <div className='small text-start btn btn-secondary'>Current Working Here</div>
          </div>
        )}
      </Card.Body>
    </Card>
  </div>
</Tab>







  <Tab eventKey="tab3" title="Education">
    <div className="tab-content">
    <div className="tab-content">
    <Card className='mb-3 mt-4'>
      <Card.Body>
        <Card.Title className='small font-weight-bold text-start'>{education_name}</Card.Title>
        <div className='small text-secondary text-start'>{specilization}</div>
        <div className='small text-secondary text-start'>{university_name}</div>
        <div className="d-flex flex-wrap align-items-center my-3">
          <div>
              <small className='d-flex justify-content-start text-light-custom p-1'>Course started date</small>
              <div className="d-flex align-items-center text-secondary small mb-2 py-2 mb-md-0 margin-right-custom">
                <PiCalendarBlankThin className="icon-size" /> 
                <span className="ml-1">{course_start_date}</span>
           </div>
          </div>

          <div>
              <small className='d-flex justify-content-start text-light-custom'>Course Ended date</small>
              <div className="d-flex align-items-center text-secondary small pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
                <PiCalendarBlankThin className="icon-size" /> 
                <span className="ml-1">{course_completed_date}</span>
           </div>
          </div>

        </div>

        {currently_pursuing === 'Yes' && (
          <div className='d-flex justify-content-start'>
            <div className='small text-start btn btn-secondary'>Current Pursuing</div>
          </div>
        )}
      </Card.Body>
    </Card>
  </div>
    </div>
  </Tab>



  <Tab eventKey="tab4" title="Attached Resume">
  <div className="tab-content mt-4">
            {attachedCV ? (
              <div className="pdf-viewer">
                <Worker workerUrl={pdfWorker}>
                  <Viewer fileUrl={attachedCV} />
                </Worker>
              </div>
            ) : (
              <p>No CV attached.</p>
            )}
          </div>
   </Tab>




</Tabs>

        </div>
      </div>
    </div>
  );
};

export default UserData;



