

import React, { useState, useCallback, useEffect } from 'react';
import './UserDataWrapper.css';
import { BsXLg } from 'react-icons/bs';
import UserData from '../../reusable/userdata/UserData';
import { Link } from 'react-router-dom';



interface UserDataWrapperProps {
  children: React.ReactNode;
}

interface UserDataProps {
  id: number;
  jobseeker: string;
  jobskeer_id: number;
  jobseeker_package: string;
  jobseeker_experience: string;
  jobseeker_education: string;
  jobseeker_locations: string[];
  jobseeker_role:string;
  jobseeker_mobilenumber: number;
  jobseeker_mail: string;
  jobseeker_pref_locations: string[];
  jobseeker_notice_period: string;
  jobseeker_about:string;
  jobseeker_skills: string[];
  jobseeker_tools: string[];
  attachedCV: string;
  jobseeker_birth: string;
  jobseeker_gender: string;
  jobseeker_martial_status: string;
  jobseeker_physically_challenged: string;
  onClick?: () => void;

  //Jobseeker Work Experience
  jobseker_company_name: string;
  jobRole: string;
  working_from_date: string;
  working_to_date: string;
  currently_working:string;

  // Jobskeer Education Details
  education_name: string;
  specilization: string;
  university_name: string;
  course_start_date: string;
  course_completed_date: string;
  currently_pursuing:string;
  
}




const UserDataWrapper: React.FC<UserDataWrapperProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const latestUserData: UserDataProps[] = [
    {
      id: 1,
      jobseeker: 'Subramanyam N',
      jobskeer_id: 96526,
      jobseeker_package: '₹17.3 LPA',
      jobseeker_experience: '5yr 2m ',
      jobseeker_locations: ['Hdyerabad', 'Banglore'],
      jobseeker_role:'UX Developer',
      jobseeker_education: 'Jntu - Hyderabad Univercity',
      jobseeker_skills: ['SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js'],
      jobseeker_mobilenumber:9035115125,
      jobseeker_mail:'Subbu956@gmail.com',
      jobseeker_pref_locations: ['Hdyerabad', 'Banglore'],
      jobseeker_notice_period: '2 Months',
      jobseeker_about:'I am a UX developer with 7.6 years of experience. with web applications using Java technologies. I have a strong knowledge of object-oriented programming, data structures, algorithms, frameworks such as Spring Boot, Hibernate, and Angular & Micro Services ',
      jobseeker_tools:['SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js'],
      attachedCV: require('../../assets/images/resume.pdf'),
      jobseeker_birth: '12-09-1994',
      jobseeker_gender: 'Male',
      jobseeker_martial_status: 'Single',
      jobseeker_physically_challenged: 'No',

      // Jobseeker Workexperience
      jobseker_company_name: 'Mircosoft Inc',
      jobRole: 'UX Developer - II' ,
      working_from_date: '12-02-2017' ,
      working_to_date: '',
      currently_working:'Yes' ,

      // Jobskeer Education Details
      education_name:'Graducation',
      specilization: 'Electrical & Electrinics Engineerig',
      university_name:'JNTU Hyderabad',
      course_start_date:'13-April-2014',
      course_completed_date:'June-April-2017',
      currently_pursuing: 'No',
      
    },
  ];





  useEffect(() => {
    return () => {
      setIsDrawerOpen(false);
    };
  }, []);

  return (
    <div className="drawer-wrapper2">
      <div className={`drawer2 ${isDrawerOpen ? 'open' : ''}`}>
        <div className="d-flex justify-content-between align-items-center d-flex flex-wrap">
          <div className="pt-4 pb-4">
            <h6 className="d-flex justify-content-start fs-6 text-dark fw-bold">Your Work Profile</h6>
            <span className="d-flex justify-content-start">Please review your application</span>
          </div>
          <div className='d-flex justify-content-end'>
            <Link to="/sucessapplied" className="mx-1 btn btn-primary">Yes, Apply Now</Link>
            <Link to="/jobProfileForms" className="mx-1 btn btn-outline-primary">Edit Profile</Link>
          </div>
          <div>
            {isDrawerOpen && (
              <BsXLg onClick={closeDrawer} className="close-icon" />
            )}
          </div>
        </div>
        <hr />
        <div className="col-md-12" style={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto' }}>
          {latestUserData.map((job) => (
            <UserData
              key={job.id}
              jobseeker={job.jobseeker}
              jobskeer_id={job.jobskeer_id}
              jobseeker_package={job.jobseeker_package}
              jobseeker_education={job.jobseeker_education}
              jobseeker_experience={job.jobseeker_experience}
              jobseeker_locations={job.jobseeker_locations}
              jobseeker_role={job.jobseeker_role}
              jobseeker_skills={job.jobseeker_skills}
              jobseeker_mobilenumber={job.jobseeker_mobilenumber}
              jobseeker_mail={job.jobseeker_mail}
              jobseeker_pref_locations={job.jobseeker_pref_locations}
              jobseeker_notice_period={job.jobseeker_notice_period}
              attachedCV={job.attachedCV}
              jobseeker_about={job.jobseeker_about}
              jobseeker_tools={job.jobseeker_tools}
              jobseeker_birth={job.jobseeker_birth}
              jobseeker_gender={job.jobseeker_gender}
              jobseeker_martial_status={job.jobseeker_martial_status}
              jobseeker_physically_challenged={job.jobseeker_physically_challenged}

              jobseker_company_name={job.jobseker_company_name}
              jobRole={job.jobRole}
              working_from_date = {job.working_from_date}
              working_to_date = {job.working_to_date}
              currently_working = {job.currently_working}

              education_name={job.education_name}
              specilization={job.specilization}
              university_name={job.university_name}
              course_start_date={job.course_start_date}
              course_completed_date={job.course_completed_date}
              currently_pursuing={job.currently_pursuing}

            />
          ))} 


        </div>



      </div>
      <div className="main-content">
        <div onClick={openDrawer} className="btn px-3 fs-3 py-2">
          <button className="mx-1 btn btn-primary">Apply Now</button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default UserDataWrapper;
