

import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';
import { Card, Row, Col, Button, DropdownButton } from 'react-bootstrap';
import { PiShareNetworkThin  } from "react-icons/pi";
import { IoShareSocial } from "react-icons/io5";


interface JobCardReUseProps {
    id: number;
    jobRole: string;
    companyName: string;
    package: string;
    experience: string;
    locations: string[];
    education: string;
    skills: string[];
    postedDate: string;
    image: string;
  }


const CompanyDetails: React.FC = () => {

   const { companyId } = useParams<{ companyId: string }>();


  const JobCard = [
  {
    id: 1,
    companyname: 'Adobe Inc',
    founded: '1982',
    revenue : '$500B - ₹700B',
    industry: ['Software Consulting', ' IT Services'],
    companysize: '10,000+',
    website: {
      label: 'Adobe Website',
      url: 'https://www.adbe.com'
    },
    aboutcompany: 'Adobe a Software company that helps to create software and manages its solutions. It offers a complete package using modern technologies like AI, ML, Cloud computing, Automation, etc. It helps large enterprises with a significant contribution. Adobe is the global leader in digital media and digital marketing solutions. Our creative, marketing and document solutions empower everyone – from emerging artists to global brands – to bring digital creations to life and deliver immersive, compelling experiences to the right person at the right moment for the best results. In short, Adobe is everywhere, and we’re changing the world through digital experiences. ',
    
  } ]





  const jobRecommendations: JobCardReUseProps[] = [
    {
      id: 1,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '13-18 Lacs',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Becherlors, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
    },
    
    {
      id: 2,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '38Lacs',
      experience: '6-10',
      education:'Becherlors, ',
      locations: ['India', 'San Francisco'],
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
    },
  ];




  const location = useLocation();
  const navigate = useNavigate();
 
  const handleCardClick = (companyId: number) => {
    navigate(`/companydetails/${companyId}`);
  };


  return (
    <div className='js-pg-hg'>
      <div className="container">
      <div className='row'>
        <div className='col-md-9'>


        <Card className='mb-3 p-3'>
   <Card.Body>
      {JobCard.map((card, index) => (
      <div key={index} className='mb-0'>
         <div className='d-flex justify-content-between align-items-center'>
            <Card.Title className='font-weight-bold'>{card.companyname}</Card.Title>
         </div>
         <span className='d-flex justify-content-start text-primary mb-3 small'>Founded {card.founded}</span>



         <div className='d-flex align-items-center text-secondary mb-2'>
            <span className="">Revenue: <span className='fw-medium'>{card.revenue}</span></span>
        </div>


         <div className='d-flex align-items-center text-secondary mb-2'>
            <span className="">Industry: <span className='fw-medium'>{card.industry}</span></span>
        </div>

        <div className='d-flex align-items-center text-secondary mb-2'>
            <span className="">Company Size: <span className='fw-medium'>{card.companysize}</span></span>
        </div>


        <div className="close-line-container"><hr className='w-100'/></div>
        <div className='d-flex align-items-center justify-content-between text-secondary '>
                  
                  <div>
                  <button className='btn btn-primary'>Follow</button>
                  </div>                  

                  <div className='d-flex'>
                  <a href={JobCard[0].website.url} target="_blank" rel="noopener noreferrer">
                   {JobCard[0].website.label}
                  </a>
                  <div className='mx-3'><a><IoShareSocial className="icon-size" /></a></div>
                  </div>

        </div>
      </div>
      ))}
   </Card.Body>
</Card>




<Card className='mb-3 p-3'>
   <Card.Body>
      {JobCard.map((card, index) => (
         <div key={index} className='mb-2'>
            <div className='d-flex justify-content-between align-items-center'>
               <Card.Title className='small font-weight-bold'>About Company</Card.Title>
            </div>
                     <div className='d-flex justify-content-between align-items-center text-start font-weight-normal'>
                        <span className='text-secondary'>{card.aboutcompany}</span>
                     </div>
         </div>
      ))}
   </Card.Body>
</Card>

             

        </div>

        <div className='col-md-3'>
            <div>
             <p className='d-flex justify-content-start text-secondary'>Job Recommendations</p>
             {jobRecommendations.map((job) => (
                  <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  education={job.education}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                />
              ))}
              </div>
        </div>
      </div>
    </div>
      
    </div>
    

  );
};

export default CompanyDetails;