

import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';
import { Card, Row, Col, Button, DropdownButton } from 'react-bootstrap';
import "./JobDetails.css"
import UserDataWrapper from '../../wrappers/userdatawrapper/UserDataWrapper';
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { MdBookmarkBorder } from "react-icons/md";
import { IoMdShare } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { PiWalletThin, PiSuitcaseSimpleThin, PiMapPinLineThin, PiStudentThin, PiWrenchThin, PiBookmarkSimpleThin, PiShareNetworkThin  } from "react-icons/pi";


interface JobCardReUseProps {
   id: number;
   jobRole: string;
   companyName: string;
   package: string;
   experience: string;
   locations: string[];
   education ?: string;
   skills: string[];
   postedDate: string;
   image: string;
 }


const JobDetails: React.FC = () => {

  const { jobId } = useParams<{ jobId: string }>();
  const jobposteddate = "Today"

  const JobCard = [
  {

    id: 1,
    title: 'Softaware Developer',
    company: 'Meta Inc',
    salary: '₹18.4L - ₹19.8L',
    experience: '3-5',
    locations: ['Hdyerabad', 'Banglore'],
    qualifications: 'MBA, Any Graduation',
    image2: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',


    responsibilities: ['Create and manage databases and their related components',
    'Writing Stored Procedures, Functions as per business logic',
    'Creating and maintaining indexes',
    'Create, use, and maintain data views',
    'Create and implement triggers',
    'Implement data integrity by using the rules, and unique identifiers.',
    'Optimize the queries as well as stored procedures to make the application runs faster',
    'Create SQL Jobs'
  ],

  requirements: ['User defined functions and stored procedures.',
    'Expert knowledge of Microsoft T-SQL coding best practices',
    'Proficient in database normalization techniques.',
    'Detail-driven, efficient and able to work quickly in a fast-paced environment'
  ],

  keyskills: ['SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js','SQL Server', 'Spring Integration', 'Node.js'],

  aboutcompany: 'Adobe a Software company that helps to create software and manages its solutions. It offers a complete package using modern technologies like AI, ML, Cloud computing, Automation, etc. It helps large enterprises with a significant contribution.',

  } ]



  const jobRecommendations: JobCardReUseProps[] = [
   {
     id: 1,
     jobRole: 'Software Engineer',
     companyName: 'Microsoft Inc.',
     package: '₹18.4L - ₹19.8L',
     experience: '3-6',
     locations: ['Hdyerabad', 'Banglore'],
     skills: ['JavaScript', 'React', 'Node.js'],
     postedDate: 'Today',
     image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
   },

   {
     id: 2,
     jobRole: 'UX/UI Designer',
     companyName: 'Pixel Inc.',
     package: '₹18.4L - ₹19.8L',
     experience: '6-10',
     locations: ['Hdyerabad', 'Banglore'],
     skills: ['JavaScript', 'React', 'Node.js'],
     postedDate: 'April 25, 2024',
     image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
   },
   {
      id: 3,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '₹15.4L - ₹17.5L',
      experience: '3-6',
      locations: ['Hdyerabad', 'Banglore'],
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
    },

    {
      id: 4,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '₹17.3L - ₹18.2L',
      experience: '6-10',
      locations: ['Hdyerabad', 'Banglore'],
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
    },
 ];




 const location = useLocation();
 const navigate = useNavigate();


 const handleJobClick = (jobId: number) => {
   navigate(`/jobdetails/${jobId}`);
 };
 
 const handleCompanyClick = (companyName: string) => {
   navigate(`/companydetails/${companyName}`);
 };
 
 const handleImageClick = (jobId: number) => {
   navigate(`/jobdetails/${jobId}`);
 };





  return (
    <div className='my-5'>
      <div className="container my-3">
      <div className='row'>
        <div className='col-md-8'>



        <Card className='mb-4 no-shadow rounded-4 border shadow-none rounded-4 p-3'>
   <Card.Body>
      {JobCard.map((card, index) => (
      <div key={index} className='mb-0'>


         <div className='d-flex justify-content-between align-items-center mb-2'>
            <div>
            <Card.Title className='fw-semibold h5'>{card.title}</Card.Title>
            <div className='small text-primary text-start fw-medium'>{card.company}</div>
            </div>
            <div className="d-flex align-items-center text-primary">
            <img src={card.image2} alt={`${card.company} logo`} className="job-card-image" width={36} />
            </div>
          </div>



         <div className='pt-3'>

         <div className="d-flex flex-wrap align-items-center">
  <div className="d-flex align-items-center text-secondary pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
  <LuWallet className="icon-size fs-6" />  <span className="ms-2 fw-normal">{card.salary}</span>
  </div>

  <div className="d-flex align-items-center text-secondary pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
  <PiSuitcaseSimpleLight className="icon-size" /> <span className="ms-2 fw-normal">{card.experience} Years</span>
  </div>

  <div className="d-flex align-items-center text-secondary py-2 margin-right-custom">
  <CiLocationOn className="icon-size" /><span className="ms-2 fw-normal">{card.locations.join(', ')}</span>
  </div>
</div>


<div className="d-flex flex-wrap align-items-center">
  <div className="d-flex align-items-center text-secondary pr-5 mb-2 py-2 mb-md-0 margin-right-custom">
  <HiOutlineAcademicCap className="icon-size" /> <span className="ms-2 fw-normal">{card.qualifications}</span>
  </div>
</div>


<hr className='border-color' />

         <div className="d-flex flex-wrap align-items-center justify-content-between">
           <div className="d-flex align-items-center text-secondary small pr-5 mb-md-0 margin-right-custom">
               <div className='d-flex align-items-center text-secondary small'>
               <PiBookmarkSimpleThin className="icon-size" size={24}  />
                  {/* <button className='mx-3 btn btn-primary'>Apply</button> */}
                   <UserDataWrapper>

                   </UserDataWrapper>
               </div>
            </div>

            <div className="d-flex align-items-center text-secondary mb-2 py-2 mb-md-0">
               <div className='d-flex align-items-center text-secondary'>
               <span className="mx-3">Posted: {jobposteddate}</span>
               <IoShareSocial className="icon-size" size={24} />
               </div>

               </div>


            </div>

         </div>
      </div>
      ))}
   </Card.Body>
</Card>



<Card className='mb-4 no-shadow rounded-4 border p-3'>
   <Card.Body>
      {JobCard.map((card, index) => (
         <div key={index} className='mb-4'>
            <div className='d-flex justify-content-between align-items-center'>
               <Card.Title className='fs-5 fw-semibold'>Job Description</Card.Title>
            </div>




            {/* <div className="pt-3">
   <h6 className='text-start'>Responsibilities:</h6>
   <ul className='pt-2 small-bullet'>
      {card.responsibilities.map((responsibility, idx) => (
         <li key={idx} className='mb-2 text-start align-items-start'>
            <div className='badge badge-light text-secondary fw-normal fs-6 text-wrap text-start'>{responsibility}</div>
         </li>
      ))}
   </ul>
</div> */}


<div className="pt-3">
  <h6 className="text-start">Responsibilities:</h6>
  <ul className="pt-2 small-bullet custom-bullet">
    {card.responsibilities.map((responsibility, idx) => (
      <li key={idx} className="mb-2 text-start">
        <div className="badge badge-light text-secondary fw-normal fs-6 text-wrap text-start">
          {responsibility}
        </div>
      </li>
    ))}
  </ul>
</div>



<div className="pt-3">
   <h6 className='text-start'>Requirements:</h6>
   <ul className='pt-2 small-bullet custom-bullet'>
      {card.requirements.map((requirements, idx) => (
         <li key={idx} className='mb-2 text-start'>
            <div className='badge badge-light text-secondary fw-normal fs-6 text-wrap text-start'>{requirements}</div>
         </li>
      ))}
   </ul>
</div>





<div className="pt-3">
   <h6 className='text-start'>Key Skills:</h6>
   <ul className='pt-2 list-unstyled d-flex flex-wrap'>
      {card.keyskills.map((keyskill, idx) => (
         <li key={idx} className='me-2 mb-2'>
            <div className='border text-secondary fw-normal p-2 px-3'>{keyskill}</div>
         </li>
      ))}
   </ul>
</div>


         </div>
      ))}
   </Card.Body>
</Card>



<Card className='mb-3 p-3 no-shadow rounded-4 border'>
   <Card.Body>
      {JobCard.map((card, index) => (
         <div key={index} className=''>
            <div className='d-flex justify-content-between align-items-center'>
               <Card.Title className='fs-5 fw-semibold mb-3'>About Company</Card.Title>
            </div>
                     <div className='d-flex justify-content-between align-items-center text-start font-weight-normal'>
                        <span className='text-secondary fs-6'>{card.aboutcompany}</span>
                     </div>
         </div>
      ))}
   </Card.Body>
</Card>



        </div>

        <div className='col-md-4'>
            <div>
             <p className='d-flex justify-content-start p-2 fw-medium fs-6 text-secondary'>Job Recommendations</p>
             {jobRecommendations.map((job) => (
                  <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                  onJobClick={() => handleJobClick(job.id)}
                  onImageClick={() => handleImageClick(job.id)}
                  onCompanyClick={() => handleCompanyClick(job.companyName)}
                />
              ))}
              </div>
        </div>
      </div>
    </div>

    </div>


  );
};

export default JobDetails;