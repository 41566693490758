import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';


interface ProfileFormProps {
  onSubmit: (data: EducationData) => void;
}

interface EducationData {
  degreeType: string;
  specialization: string;
  startDate: string;
  endDate: string;
  currentlyPursuing: boolean;
}

const Education: React.FC<ProfileFormProps> = ({ onSubmit }) => {
  const [degreeType, setDegreeType] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentlyPursuing, setCurrentlyPursuing] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      degreeType,
      specialization,
      startDate,
      endDate,
      currentlyPursuing
    });
  };

  const degreeTypes = ['Bachelors', 'Masters', 'PhD'];
  const specializations = ['Computer Science', 'Electrical Engineering', 'Business Administration'];

  return (
    <Form onSubmit={handleSubmit} className="my-form">
      <legend>
        <h6 className="fw-medium fs-4">Education Details</h6>
        <hr className="w-100 border-color-2" />
      </legend>

      <div className='row'>


      <Form.Group controlId="degreeType" className='col-md-6 col-sm-12 mb-4'>
        <Form.Label>Degree Type</Form.Label>
        <Form.Control className='form-select form-control-custom'  as="select" value={degreeType} onChange={(e) => setDegreeType(e.target.value)} required>
          <option className='' value="">Select</option>
          {degreeTypes.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </Form.Control>
      </Form.Group>


      <Form.Group controlId="specialization" className='col-md-6 col-sm-12 mb-4'>
        <Form.Label>Specialization</Form.Label>
        <Form.Control className='form-select form-control-custom' as="select" value={specialization} onChange={(e) => setSpecialization(e.target.value)} required>
          <option value="">Select</option>
          {specializations.map((spec) => (
            <option key={spec} value={spec}>{spec}</option>
          ))}
        </Form.Control>
      </Form.Group>



      <Form.Group controlId="startDate" className='col-md-6 col-sm-12 mb-4'>
        <Form.Label>Course Start Date</Form.Label>
        <Form.Control className='form-control-custom' type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
      </Form.Group>


      <Form.Group controlId="endDate" className='col-md-6 col-sm-12 mb-4'>
        <Form.Label>Course End Date</Form.Label>
        <Form.Control className='form-control-custom' type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
      </Form.Group>

      <Form.Group controlId="currentlyPursuing" className="col-md-6 col-sm-12 mb-3">
                <Form.Check
                  type="checkbox"
                  label="Currently Pursuing"
                  checked={currentlyPursuing}
                  onChange={(e) => setCurrentlyPursuing(e.target.checked)}
                  className="pt-2"
                />
        </Form.Group>



      </div>
      <Button type="submit" className="btn-forms-outline">Back</Button>
      <Button type="submit" className='btn-forms-primary'>Save and Continue</Button>
    </Form>
  );
};

export default Education;