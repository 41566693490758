import React, { useState } from 'react';
import './SignUp.css';
import { Link } from 'react-router-dom';
import googleicon from '../assets/icons/google.svg';
import sign2 from '../assets/images/sign2.svg';
import { FaWhatsapp, FaEnvelope, FaSms } from 'react-icons/fa';

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [location, setLocation] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Signup data:', {
      firstName,
      lastName,
      email,
      password,
      mobileNumber,
      location,
    });
  };

  return (
    <div className='container'>
      <div className='row my-5'>
        <div className='col-xl-4'>
          <div className='card shadow-none border m-2 text-start rounded-4'>
            <div className='card-body p-4'>
              <img src={sign2} alt="jobsco signup" className="sign_image" />
              <ul className="list-group">
                <li className="list-group-item">
                  Build a profile, and connect with recruiters
                </li>
                <li className="list-group-item">
                  Get updates from us
                </li>
                <li className="list-group-item">
                  Find jobs and grow your career
                </li>
                <li className="list-group-item">
                  Get 1,000+ job updates every day
                </li>
                <li className="list-group-item">
                  Recruiters will connect with you directly
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='col-xl-8'>
          <div className="card shadow-none border m-2 text-center rounded-4">
            <div className='card-body'>
              <div className='row p-4'>
                <div className='col-xl-6'>
                  <div className="pt-1 pb-4 text-start">
                    <h5 className='fw-bold'>Create Your Jobsco Profile</h5>
                    <span className='text-secondary fw-medium'>
                    Find Your Dream Job With JobsCo – Your Success!
                    </span>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className='form-label'>First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        placeholder="Enter your first name"
                        className='form-control-custom'
                      />
                    </div>
                    <div className="form-group">
                      <label className='form-label'>Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        placeholder="Enter your last name"
                        className='form-control-custom'
                      />
                    </div>
                    <div className="form-group">
                      <label className='form-label'>Email ID</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder="Enter your email ID"
                        className='form-control-custom'
                      />
                    </div>
                    <div className="form-group">
                      <label className='form-label'>Password</label>
                      <input
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="Enter your password"
                        className='form-control-custom'
                      />
                    </div>
                    <div className="form-group">
                      <label className='form-label'>Mobile Number</label>
                      <input
                        type="tel"
                        value={mobileNumber}
                        onChange={(event) => setMobileNumber(event.target.value)}
                        placeholder="Enter your mobile number"
                        className='form-control-custom'
                      />
                    </div>

                    <div className="form-group d-flex align-items-start">
                      <input
                        type="checkbox"
                        id="updatesCheckbox"
                        className="form-check-input"
                      />
                      <label
                        htmlFor="updatesCheckbox"
                        className="text-secondary ms-2 text-start"
                      >
                        Send me important updates and promotions via
                        <FaSms className="ms-1 me-1" /> SMS,
                        <FaEnvelope className="ms-1 me-1" /> email, and
                        <FaWhatsapp className="ms-1 me-1" /> WhatsApp
                      </label>
                    </div>

                    <div className="d-flex justify-content-start">
                      <Link
                        to="/jobProfileForms"
                        className="btn-primary py-3 rounded-1 w-100 text-decoration-none fw-bold fs-6"
                      >
                        Create an Account
                      </Link>
                    </div>
                  </form>
                </div>

                <div className='col-xl-6 d-flex align-items-center'>
                  <div className="mx-2 d-flex flex-column align-items-center justify-content-center">
                    <div style={{ height: '40px', width: '1px', backgroundColor: '#bbc1cb' }}></div>
                    <div className="text fw-medium my-2">Or</div>
                    <div style={{ height: '40px', width: '1px', backgroundColor: '#bbc1cb' }}></div>
                  </div>

                  <div className=''>
                    <label className='text-start pb-2'>Continue with</label>
                    <button type="button" className="login-with-google-btn">
                      Sign Up with Google
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
