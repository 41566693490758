import React from 'react';
import './TwoCol.css';

type TwoColType = {
  title: string;
  description?: string;
  imgUrl?: JSX.Element | string;
  className: string;
  versa: boolean;
};

const TwoCol: React.FC<{ twoCol: TwoColType }> = ({ twoCol }) => {
  const { title, description, imgUrl, className, versa } = twoCol;

  return (
    <div className={versa ? "mt-5" : "mt-5"}>
      <div className="row justify-content-center align-items-center">
        {versa ? (
          <>
            <div className="col-md-5 d-flex align-items-center justify-content-center">
              <div className='col-lg-10 text-lg-start text-md-start text-xl-start text-center'>
                <h3 className="card-title  fw-semibold pb-3">{title}</h3>
                <p className="fs-5 card-text">{description}</p>
              </div>
            </div>
            <div className="col-md-7 d-flex justify-content-center d-none d-md-block">
              {typeof imgUrl === 'string' ? (
                <img className="card-img-top col-img" src={imgUrl} alt={title} />
              ) : (
                imgUrl
              )}
            </div>
          </>
        ) : (
          <>
            <div className="col-md-7 d-flex justify-content-center d-none d-md-block">
              {typeof imgUrl === 'string' ? (
                <img className="card-img-top col-img" src={imgUrl} alt={title} />
              ) : (
                imgUrl
              )}
            </div>
            <div className="col-md-5 d-flex align-items-center justify-content-center">
            <div className='col-lg-10 text-lg-start text-md-start text-xl-start text-center'>
              <h3 className="card-title fw-semibold pb-3">{title}</h3>
              <p className="fs-5 card-text">{description}</p>
            </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TwoCol;





// import React from 'react';
// import './TwoCol.css';

// type TwoColType = {
//   title: string;
//   description?: string;
//   imgUrl?: JSX.Element | string;
//   className: string;
//   versa: boolean;
// };

// const TwoCol: React.FC<{ twoCol: TwoColType }> = ({ twoCol }) => {
//   const { title, description, imgUrl, versa } = twoCol;

//   return (
//     <div className="two-col-section mt-5">
//       <div className="row justify-content-center align-items-center">
//         {versa ? (
//           <>
//             <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
//               <div className="text-section">
//                 <h4 className="card-title pb-2">{title}</h4>
//                 {description && <p className="card-text">{description}</p>}
//               </div>
//             </div>
//             <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center img-section">
//               {typeof imgUrl === 'string' ? (
//                 <img className="col-img" src={imgUrl} alt={title} />
//               ) : (
//                 imgUrl
//               )}
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center img-section">
//               {typeof imgUrl === 'string' ? (
//                 <img className="col-img" src={imgUrl} alt={title} />
//               ) : (
//                 imgUrl
//               )}
//             </div>
//             <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
//               <div className="text-section">
//                 <h4 className="card-title pb-2">{title}</h4>
//                 {description && <p className="card-text">{description}</p>}
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TwoCol;
