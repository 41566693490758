

import React, { useState } from 'react';
import './UnreadMessages.css';
import ChatBox from '../../chat/chatbox/ChatBox';

interface Message {
  id: number;
  sender: {
    firstName: string;
    lastName: string;
    profileIcon?: string;
  };
  company: string;
  timestamp: Date;
  content: string;
}

interface UnreadMessagesProps {
  messages: Message[];
}

const UnreadMessages: React.FC<UnreadMessagesProps> = ({ messages }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);

  const formatDate = (date: Date) => {
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - date.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 1) return `Today, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    if (diffDays < 2) return `Yesterday, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    if (diffDays < 7) return `${diffDays} days ago`;
    return date.toLocaleDateString();
  };

  const truncateMessage = (message: string) => {
    const words = message.split(' ');
    return words.length > 8 ? words.slice(0, 8).join(' ') + '...' : message;
  };

  const openChat = (message: Message) => {
    setSelectedMessage(message);
    setIsChatOpen(true);
  };

  const closeChat = () => {
    setIsChatOpen(false);
    setSelectedMessage(null);
  };

  return (
    <div className="unread-messages-container">
      {messages.map((message) => (
        <div key={message.id} className="message-card border-bottom d-block p-4" onClick={() => openChat(message)}>
          <div className="message-header d-flex align-items-start justify-content-between">
            {message.sender.profileIcon ? (
              <img src={message.sender.profileIcon} alt="Profile" className="profile-icon" />
            ) : (
              <div className="profile-initials">
                {message.sender.firstName.charAt(0)}{message.sender.lastName.charAt(0)}
              </div>
            )}
            <div className="message-info flex-grow-1">
              <h6 className="sender-name fs-6">
                {message.sender.firstName} {message.sender.lastName}
              </h6>
              <p className="company-name">{message.company}</p>
              <p className="message-content pt-2 pb-0 mb-0">
                {truncateMessage(message.content)}
              </p>
            </div>
            <div className="message-timestamp text-end">
              {formatDate(message.timestamp)}
              <span className="badge rounded-pill mx-1 message-count-badge">
                5
              </span>
            </div>
          </div>
        </div>
      ))}

      {selectedMessage && (
        <ChatBox
          isOpen={isChatOpen}
          closeChat={closeChat}
        />
      )}
    </div>
  );
};

export default UnreadMessages;