// import React, { useState } from 'react';
// import { Button, Form, Badge } from 'react-bootstrap';
// import WorkExperience from '../workexperience/WorkExperience';

// interface SkillsFormProps {
//   onSubmit: (data: any) => void;
// }

// const SkillsForm: React.FC<SkillsFormProps> = ({ onSubmit }) => {
//   const skillsArray = ['JavaScript', 'React', 'Node.js', 'HTML', 'CSS', 'TypeScript'];
//   const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
//   const [searchValue, setSearchValue] = useState('');
//   const [activeForm, setActiveForm] = useState<'skills' | 'WorkExperience'>('skills');

//   const handleAddSkill = (skill: string) => {
//     if (!selectedSkills.includes(skill)) {
//       setSelectedSkills([...selectedSkills, skill]);
//       setSearchValue('');
//     }
//   };

//   const handleRemoveSkill = (skill: string) => {
//     setSelectedSkills(selectedSkills.filter((s) => s !== skill));
//   };

//   const filteredSkills = skillsArray.filter((skill) =>
//     skill.toLowerCase().includes(searchValue.toLowerCase())
//   );

//   const handleSkillSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     onSubmit(selectedSkills);
//     setActiveForm('WorkExperience');
//   };

//   const renderForm = () => {
//     switch (activeForm) {
//       case 'skills':
//         return (
//           <form onSubmit={handleSkillSubmit} className="my-form">
//             <legend>
//               <h6 className="fw-medium">Skills</h6>
//               <hr className="w-100 border-color-2" />
//             </legend>

//             <Form.Group controlId="addSkills">
//             <Form.Label className="form-label">Add Skills</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter skill name"
//               value={searchValue}
//               onChange={(e) => setSearchValue(e.target.value)}
//               className="form-control-lg form-control-custom"
//             />
//           </Form.Group>
//           {searchValue && (
//             <div className="search-results pt-3">
//               {filteredSkills.map((skill) => (
//                 <Button
//                   key={skill}
//                   variant={selectedSkills.includes(skill) ? 'success' : 'outline-primary'}
//                   onClick={() => handleAddSkill(skill)}
//                   style={{ margin: '5px' }}
//                 >
//                   {skill}
//                 </Button>
//               ))}
//             </div>
//           )}
//           <div className="selected-skills mt-4">
//             {selectedSkills.map((skill) => (
//               <Badge
//                 key={skill}
//                 pill
//                 className="custom-badge"
//                 onClick={() => handleRemoveSkill(skill)}
//               >
//                 {skill} <span style={{ marginLeft: '5px' }}>x</span>
//               </Badge>
//             ))}
//           </div>

//             <Button type="submit" className="mt-3 fw-medium">
//             Save and Continue
//             </Button>
//           </form>
//         );

//       case 'WorkExperience':
//         return <WorkExperience onSubmit={onSubmit} />;

//       default:
//         return null;
//     }
//   };

//   return <div>{renderForm()}</div>;
// };

// export default SkillsForm;





import React, { useState } from 'react';
import { Button, Form, Badge, ListGroup } from 'react-bootstrap'; // Added ListGroup for dropdown style
import WorkExperience from '../workexperience/WorkExperience';

interface SkillsFormProps {
  onSubmit: (data: any) => void;
}

const SkillsForm: React.FC<SkillsFormProps> = ({ onSubmit }) => {
  const skillsArray = ['Java', 'JavaScript', 'React', 'Node.js', 'HTML', 'CSS', 'TypeScript']; // Example skills array
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [activeForm, setActiveForm] = useState<'skills' | 'WorkExperience'>('skills');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleAddSkill = (skill: string) => {
    if (!selectedSkills.includes(skill)) {
      setSelectedSkills([...selectedSkills, skill]);
      setSearchValue(''); // Reset search value after adding skill
      setShowDropdown(false); // Hide dropdown after selection
    }
  };

  const handleRemoveSkill = (skill: string) => {
    setSelectedSkills(selectedSkills.filter((s) => s !== skill));
  };

  const filteredSkills = skillsArray.filter((skill) =>
    skill.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSkillSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(selectedSkills);
    setActiveForm('WorkExperience'); // Switch to WorkExperience form after submission
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value === '') {
      setShowDropdown(false);
    } else {
      setShowDropdown(true); // Show dropdown if input is not empty
    }
  };

  const renderForm = () => {
    switch (activeForm) {
      case 'skills':
        return (
          <form onSubmit={handleSkillSubmit} className="my-form">
            <legend>
              <h6 className="fw-medium fs-4">Skills</h6>
              <hr className="w-100 border-color-2" />
            </legend>

            <Form.Group controlId="addSkills">
              <Form.Label className="form-label">Add Skills</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter skill name"
                value={searchValue}
                onChange={handleSearchChange}
                className="form-control-lg form-control-custom"
              />
            </Form.Group>

            {/* Dropdown list for filtered skills */}
            {showDropdown && filteredSkills.length > 0 && (
              <ListGroup className="mt-2">
                {filteredSkills.map((skill) => (
                  <ListGroup.Item
                    key={skill}
                    action
                    onClick={() => handleAddSkill(skill)}
                    style={{ cursor: 'pointer' }}
                  >
                    {skill}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}

            <div className="selected-skills mt-4">
              {selectedSkills.map((skill) => (
                <Badge
                  key={skill}
                  pill
                  className="custom-badge"
                  onClick={() => handleRemoveSkill(skill)}
                >
                  {skill} <span style={{ marginLeft: '5px' }}>x</span>
                </Badge>
              ))}
            </div>

            <Button type="submit" className="btn-forms-outline">
              Back
            </Button>

            <Button type="submit" className="btn-forms-primary">
              Save and Continue
            </Button>
          </form>
        );

      case 'WorkExperience':
        return <WorkExperience onSubmit={onSubmit} />;

      default:
        return null;
    }
  };

  return <div>{renderForm()}</div>;
};

export default SkillsForm;
