import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { PiStudent, PiStudentFill} from "react-icons/pi";
import { MdOutlineArrowDropDown } from "react-icons/md";
import "./EducationModal.css";
import '../../../forms.css';


const EducationModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [degreeType, setDegreeType] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentlyPursuing, setCurrentlyPursuing] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSave = () => {
    // Handle saving education details here
    console.log({
      degreeType,
      specialization,
      startDate,
      endDate,
      currentlyPursuing
    });
    handleClose();
  };

  const degreeTypes = ['Bachelor', 'Master', 'PhD']; // Example degree types array
  const specializations = ['Computer Science', 'Electrical Engineering', 'Business Administration']; // Example specializations array
 

  return (
    <>
      <Button variant="btn text-primary" onClick={handleShow}>
        <PiStudent /><br/>
        <span className='fw-medium'>Education</span>
      </Button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton className='form-control-close'>
          
          
          <div className='d-flex align-items-center'>
          <div className='form-header-icon d-flex align-items-center'>
          <PiStudentFill size={28} />
          </div>
          
          <div>
          <Modal.Title>My Education</Modal.Title>
          </div>

        </div>

        
        </Modal.Header>
        <Modal.Body>

        <Form className="education-form p-3">

          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <Form.Group controlId="degreeType" className="mb-4">
        <Form.Label>Education</Form.Label>
        <div className="position-relative">
          <Form.Control
            as="select"
            className="form-select form-control-custom"
            value={degreeType}
            onChange={(e) => setDegreeType(e.target.value)}
          >
            <option value="">Select</option>
            {degreeTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>
          {/* <MdOutlineArrowDropDown className="dropdown-icon" /> */}
        </div>
      </Form.Group>
            </div>
            

            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <Form.Group controlId="specialization" className="mb-4">
        <Form.Label>Specialization</Form.Label>
        <div className="position-relative">
          <Form.Control
            as="select"
            className="form-select form-control-custom"
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
          >
            <option value="" >Select</option>
            {specializations.map((spec) => (
              <option key={spec} value={spec}>
                {spec}
              </option>
            ))}
          </Form.Control>
          {/* <MdOutlineArrowDropDown className="dropdown-icon" /> */}
        </div>
      </Form.Group>
            </div>

            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <Form.Group controlId="startDate" className="mb-4">
        <Form.Label>Course Start Date</Form.Label>
        <Form.Control
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control-custom"
          // className={`form-control-custom form-select-with-icon ${!startDate ? 'placeholder' : ''}`}
    
        />
      </Form.Group>
            </div>

            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
            <Form.Group controlId="endDate" className="mb-4">
        <Form.Label>Course End Date</Form.Label>
        <Form.Control
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="form-control-custom"
          // className={`form-control-custom form-select-with-icon ${!startDate ? 'placeholder' : ''}`}
        />
      </Form.Group>
            </div>

          </div>
          


      <Form.Group controlId="currentlyPursuing">
        <Form.Check
          type="checkbox"
          label={<span className="primary-text fw-medium">Currently I'm pursuing</span>}
          checked={currentlyPursuing}
          onChange={(e) => setCurrentlyPursuing(e.target.checked)}
        />
      </Form.Group>

      <div className='btn btn-outline-primary mt-4 fw-medium'><span>Add another fields </span></div>
    </Form> 





        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-start mx-3'>
          <Button variant="primary" onClick={handleSave} className='px-4 py-2'>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EducationModal;
