import React, { useState } from 'react';
import { Dropdown, DropdownButton, DropdownItem, Badge, Form, Card } from 'react-bootstrap';
import { MdOutlineArrowDropDown } from "react-icons/md";

interface SideFiltersProps {
  yearsexperience?: string;
  filtertittle: string;
  options?: string[];
}

const SideFilters2: React.FC<SideFiltersProps> = ({ yearsexperience, filtertittle, options }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [experience, setExperience] = useState(0);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter((option) => option !== value)
        : [...prevSelectedOptions, value]
    );
  };

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExperience(Number(event.target.value));
  };

  return (
    <div>
      {yearsexperience && (
        <Card className="shadow-sm mt-2 bx-center border-0 p-4">
          <Card.Body>
            <Card.Title className="d-flex justify-content-start small font-weight-bold">{filtertittle}</Card.Title>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <input
                type="range"
                min="0"
                max="20"
                className="form-range"
                value={experience}
                onChange={handleRangeChange}
              />
            </div>
            <span className="ms-2 small text-secondary d-flex justify-content-start">{experience} Years</span>
          </Card.Body>
        </Card>
      )}

      {options && (


        <Card className="shadow-sm mt-4 mb-2 bx-center border-0 p-4">
        <Card.Body className="p-0">
          <div className="d-flex justify-content-start align-items-center w-100 font-weight-bold">
            <Dropdown className="w-100">
              <Dropdown.Toggle as="div" className="w-100 fs-5 text-start custom-dropdown-toggle d-flex justify-content-between align-items-center ">
                {filtertittle}
              </Dropdown.Toggle>

              <div className="d-flex justify-content-start align-items-center w-100">
                <span className="d-flex justify-content-start ms-3 small text-secondary">
                  {selectedOptions.map((option, index) => (
                    <Badge key={index} pill bg="success" text="light" className="me-2 mb-2">
                      {option}
                    </Badge>
                  ))}
                </span>
              </div>

              <Dropdown.Menu className="w-100">
                <div className="px-3 py-2">
                  {options.map((option, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={option}
                      value={option}
                      onChange={handleCheckboxChange}
                    />
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Body>
      </Card>




      )}
    </div>
  );
};

export default SideFilters2;
