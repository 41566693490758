

import React, { useState } from 'react';
import './Header.css';
import './SignupWrapper.css';
import LoginWrapper from '../../components/loginwrapper/LoginWrapper';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };


  return (
    <div className='bg-header'>
      <div className='container'>
        <nav className="navbar py-4 navbar-expand-lg">
          <Link className="fs-3 fw-bold text-black navbar-brand" to="/" >Jobsco</Link>
          <button
            className="menu-btn navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarSupportedContent"
            aria-expanded={isNavbarOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>


          <div className={`collapse navbar-collapse justify-content-between  ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">Browse Jobs<span className="sr-only"></span></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Companies</a>
              </li>
            </ul>
            <div className="ml-auto">
              <div className="d-flex d-lg-flex align-items-center flex-column flex-lg-row">
                <div className="px-2 mb-2 mb-lg-0 py-1">
                  <LoginWrapper> </LoginWrapper>
                </div>
                <div className="px-2 mb-2 mb-lg-0 py-1">
                  <Link to="/signup" className="btn btn-primary rounded-pill px-4 py-2">Sign Up</Link>
                </div>
                <div className="px-2 py-1">
                  <button className="btn btn-success rounded-pill px-4 py-2" type="submit">Employer Login</button>
                </div>
              </div>
            </div>
          </div>


        </nav>
      </div>
    </div>
  );
};

export default Header;
