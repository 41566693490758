import React, { useState } from "react";
import "./SearchBar.css";
import { useNavigate } from "react-router-dom";
import { MdOutlineSearch, MdLocationOn } from "react-icons/md";


interface SearchBarProps {
    onSearch: (skills: string, location: string) => void;
  }


const SearchBar: React.FC = () => {
  const [skills, setSkills] = useState("");
  const [location, setLocation] = useState("");
  const navigate = useNavigate();



  const handleSearch = () => {
    navigate('/searchjobresults', { state: { skills, location } });
  };

  return (
    <div className="input-group login-search-bar py-2 align-items-center rounded-5 bg-white">
      <div className="input-group-prepend">
        <span className="p-2 mx-2 d-flex align-items-center text-secondary">
          <MdOutlineSearch size={24} />
        </span>
      </div>
      <input
        type="text"
        className="form-control login-search-input"
        placeholder="Job title, keywords, or company"
        value={skills}
        onChange={(e) => setSkills(e.target.value)}
      />

      <div className="input-group-prepend">
        <span className="p-2 mx-2 d-flex align-items-center text-secondary">
          <MdLocationOn size={24} />
        </span>
      </div>
      <input
        type="text"
        className="form-control"
        placeholder="Enter location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />

      <button
        className="btn btn-primary rounded-5 login-search-button px-4 mx-2"
        type="button"
        onClick={handleSearch}
      >
        Search
      </button>
    </div>
  );
};

export default SearchBar;