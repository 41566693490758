

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ServiceHeader.css';
import { Link } from 'react-router-dom';
import SavedJobsWrapper from '../../wrappers/savedjobswrapper/SavedJobsWrapper';
import NotificationWrapper from '../../wrappers/notificationwrapper/NotificationWrapper';
import ChatWrapper from '../../wrappers/chatwrapper/ChatWrapper';
import SideMenuWrapper from '../../wrappers/sidemenuwrapper/SideMenuWrapper';

import SearchBar from '../../reusable/searchbar/SearchBar';




const ServiceHeader: React.FC = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };


  const navigate = useNavigate();
  const handleSearch = (skills: string, location: string) => {
    navigate('/searchjobresults', { state: { skills, location } });
  };



  return (
    <div className='navbar-light bg-white'>
      <div className='container'>
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand fw-bold me-5 text-black fs-3" to="/" >Jobsco</Link>
          <button
            className="menu-btn navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarSupportedContent"
            aria-expanded={isNavbarOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>




          <div className={`collapse navbar-collapse justify-content-between  ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">

          <div className="w-75 py-2">
           <SearchBar />
          </div>







<div className='d-flex align-items-center justify-content-center'>

    <div className='d-flex px-0 align-items-center justify-content-center'>
      <div className='headericons'>
           <SavedJobsWrapper>
           </SavedJobsWrapper>
      </div>


      <div className='headericons'>
           <ChatWrapper>
           </ChatWrapper>
      </div>


      <div className='headericons'>
           <NotificationWrapper>
           </NotificationWrapper>
      </div>

    </div>

            <div className="ml-auto">
              <div className="d-flex align-items-center">
                <div className="px-2">
                  <div className="px-0 py-2">
                   <SideMenuWrapper>
                   </SideMenuWrapper>
                  </div>
                </div>
              </div>
            </div>


</div>


          </div>
        </nav>
      </div>
    </div>
  );
};

export default ServiceHeader;
