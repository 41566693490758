import React from 'react';
import './Avatar.css'; // Import Avatar styles

type ImageProps<T extends string> = { // Generic type parameter for altText
  imageUrl: string;
  altText: T;
};

const Avatar = <T extends string>(props: ImageProps<T>) => { // Use generic type
  const { imageUrl, altText } = props;
  return (
    <div className="avatar-container">
      <img src={imageUrl} alt={altText} className="avatar-image" />
    </div>
  );
};

export default Avatar;
