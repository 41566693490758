import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import FormSuccessModal from '../../forms/formsuccessmodal/FormSuccessModal';


interface ProfileFormProps {
  onSubmit: (data: PreferencesData) => void;
}

interface PreferencesData {
  states: string;
  city: string;
  notice:string;
}

const Preferences: React.FC<ProfileFormProps> = ({ onSubmit }) => {
  const [states, setStates] = useState('');
  const [city, setCity] = useState('');
  const [notice, setNotice] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      states,
      city,
      notice,
    });
  };

  const statesOptions = ['Andhra Pradesh', 'Telangana', 'Karnataka', 'Tamilnadu' , 'Maharastra'];
  const cityOptions = ['Hyderabad', 'Chennai', 'Banglore', 'Chennai'];
  const noticeOptions = ['Lessthan 15 days ', '30 days', '45 days', '2 months'];

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option: HTMLOptionElement) => option.value);
  };


  const navigate = useNavigate();
  const handleSubmit2 = () => {
    navigate('/jobSeekerDashboard');
  };





  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const goToDashboard = () => {
    navigate('/jobSeekerDashboard');
    handleClose(); // Close modal after navigation
  };



  return (
    <Form onSubmit={handleSubmit} className="my-form">
      <legend>
        <h6 className="fw-medium fs-4">Preferred Location</h6>
        <hr className="w-100 border-color-2" />
      </legend>


      <div className='row'>

        <Form.Group controlId="states" className='col-md-6 col-sm-12 mb-4'>
          <Form.Label>State</Form.Label>
          <Form.Control className='form-select form-control-custom' as="select" value={states} onChange={(e) => setStates(e.target.value)} required>
            <option value="">Select</option>
            {statesOptions.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="city" className='col-md-6 col-sm-12 mb-4'>
          <Form.Label>City</Form.Label>
          <Form.Control className='form-select form-control-custom' as="select" value={city} onChange={(e) => setCity(e.target.value)} required>
            <option value="">Select</option>
            {cityOptions.map(city => (
              <option key={city} value={city}>{city}</option>
            ))}
          </Form.Control>
        </Form.Group>

      </div>




      <legend>
        <h6 className="fw-medium fs-4 mt-4">Notice Period</h6>
        <hr className="w-100 border-color-2" />
      </legend>

      <div>
      <Form.Group controlId="notice">
          <Form.Label>Notice Period</Form.Label>
          <Form.Control className='form-select form-control-custom' as="select" value={notice} onChange={(e) => setNotice(e.target.value)} required>
            <option value="">Select</option>
            {noticeOptions.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>


      {/* <button type="submit" className="btn btn-primary mt-3" onClick={handleSubmit2}>Save Profile</button> */}

      <div>
      <Button type="submit" className="btn-forms-outline">Back</Button>
            <Button type="submit" className='btn-forms-primary' onClick={() => setShowModal(true)}>Save and Continue</Button>


      <FormSuccessModal show={showModal} handleClose={handleClose} goToDashboard={goToDashboard} />
    </div>

    </Form>
  );
};

export default Preferences;
