
import React, { useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { BsXLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { MdOutlineChatBubbleOutline } from 'react-icons/md';
import { PiChatLight } from "react-icons/pi";
import UnreadMessages from '../../chat/unreadmessages/UnreadMessages';
import userAvatar2 from '../../assets/images/profileimg2.jpg';
import userAvatar3 from '../../assets/images/profileimg3.jpg';

interface SavedJobsWrapperProps {
    children: React.ReactNode;
  }

  const ChatWrapper: React.FC<SavedJobsWrapperProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };


  const unreadmessages = 6;
  const dayname = "Today";
  const messageCount = 5;


  const messages = [
    {
      id: 1,
      sender: { firstName: 'Ganesh', lastName: 'D', profileIcon: userAvatar2 },
      company: 'Tech Co softwares & Innovations.',
      timestamp: new Date(Date.now() - 1000 * 60 * 60 * 2),
      content: 'Hello, we have an update regarding your project. Please check the details and get back to us.',
    },
    {
      id: 2,
      sender: { firstName: 'Gourav', lastName: 'Sn' },
      company: 'Innovate Ltd.',
      timestamp: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
      content: 'Hello, we have an update regarding your project. Please check the details and get back to us.',
    },
    {
      id: 3,
      sender: { firstName: 'Sravan', lastName: 'K', profileIcon: userAvatar3 },
      company: 'Tech Co softwares & Innovations.',
      timestamp: new Date(Date.now() - 1000 * 60 * 60 * 2),
      content: 'Hello, we have an update regarding your project. Please check the details and get back to us.',
    },
    {
      id: 4,
      sender: { firstName: 'Avinash', lastName: 'L' },
      company: 'Innovate Ltd.',
      timestamp: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
      content: 'Hello, we have an update regarding your project. Please check the details and get back to us.',
    },

  ];




  return (
    <div className="drawer-wrapper">
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>

      <div className='d-flex justify-content-between border-bottom py-3 p-4 px-0 align-items-center vertical-bottom'>
        <div className='ps-4 pb-3 pt-4'>
          <h5>You have {unreadmessages} unread Notification</h5>
        </div>
        <div className="fw-bold ms-4 form-control-close">
            {isDrawerOpen && (
              <BsXLg onClick={closeDrawer} className="fw-bold ms-4 form-control-close" />
            )}
          </div>

      </div>

        <div>
      <UnreadMessages messages={messages} />
        </div>

        <div>


        </div>





      </div>


      <div className="main-content">
      <div onClick={openDrawer} className="py-2 service-header-icons position-relative">
  <MdOutlineChatBubbleOutline size={32} />
  {messageCount > 0 && (
    <span className="badge rounded-pill position-absolute top-0 end-0 translate-middle message-count-badge">
      {messageCount}
    </span>
  )}
</div>
  {children}
</div>


    </div>
  );
};

export default ChatWrapper;