import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './ContactUpdateModal.css';
import { MdOutlineCall } from "react-icons/md";

const ContactUpdateModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mobileOtp, setMobileOtp] = useState('');
  const [verificationProgress, setVerificationProgress] = useState(0);
  const [countryCode, setCountryCode] = useState('+1 (USA)');
  const [isOtpSent, setIsOtpSent] = useState(false);

  const countryCodes = ['+1', '+91', '+52'];

  const handleClose = () => {
    setShowModal(false);
    setIsOtpSent(false);
    setPhoneNumber('');
    setMobileOtp('');
    setVerificationProgress(0);
  };

  const handleShow = () => setShowModal(true);

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(e.target.value);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handlePhoneVerification = () => {
    // Simulate OTP generation on server-side (replace with actual logic)
    setIsOtpSent(true);
    setVerificationProgress(100); // Simulate instant progress for now
  };

  const handleOtpVerification = () => {
    alert('OTP verification successful!');
    handleClose();
  };

  return (
    <>
      <Button className="btn btn-sm btn-outline-primary text-primary" onClick={handleShow}>
        Update
      </Button>

      <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton className="form-control-close">

        <div className='d-flex align-items-center'>
          <div className='form-header-icon d-flex align-items-center'>
          <MdOutlineCall size={28} />
          </div>

          <div>
           <Modal.Title>Contact Details</Modal.Title>
          </div>

        </div>





        </Modal.Header>


        <Modal.Body className="modal-body-custom">
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formPhoneNumber" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    className="form-control-custom phone-number-input" // Added "phone-number-input" class
                  />
                </div>
              </Form.Group>
              {isOtpSent && (
                <>
                  <Form.Group controlId="formOtp" className="mb-3">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      value={mobileOtp}
                      onChange={(e) => setMobileOtp(e.target.value)}
                      className="form-control-custom"
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={handleOtpVerification}>
                    Verify OTP
                  </Button>
                </>
              )}
            </div>
          </div>

          {!isOtpSent && (
            <Button onClick={handlePhoneVerification} className="btn-primary">
              Generate OTP
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactUpdateModal;